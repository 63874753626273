var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {

     if(window.XMLHttpRequest) // FIREFOX-
            xhr_object = new XMLHttpRequest();

        else if(window.ActiveXObject) // IE
            xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

        else
            return(false);

        xhr_object.open("GET", fichier, false);
        xhr_object.send(null);

        if(xhr_object.readyState == 4) return(xhr_object.responseText);

        else return(false);
}

function ajax_file_post(url, params) {

    var xhr_object;

    if(window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if(window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr_object.send(params);

    if(xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function changeLocale( locale, uri, site ){

	$.ajax({
		url: path_relative_root+'ajax_get_uri_by_lang.php',
		type: 'post',
		data: { lang: locale, url:uri, site: site },
		success: function (data) {
			if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
		}
	});

}

/* LIGHTBOX OPENING */
function openMultiShad(id) {

    $("#" + id).fadeTo("slow", 1, function () {
        $("#" + id).addClass("actif");
    });


    if (id == 'popup_cofidis3cb') {
        // COFIDIS FP
        calculCofidis3Cb();
        if ($('#popup_cofidis3cb .popup_container').length) {
            $("#popup_cofidis3cb").fadeIn(200, function () {
                $("#popup_cofidis3cb").slideDown(600);
            });
        }
    }

    if (id == 'selection-offer') {
        //On check individuellement chacun des sliders avec each() afin de ne pas fausser le comptage des swiper slide
        $.each($('#selection-offer-content .color_list.swiper-container'), function() {
            if ($(this).find('.swiper-slide').length > 4) {
                new Swiper($(this).get(), {
                    slidesPerView: 5,
                    spaceBetween: 6,
                    navigation: {
                        nextEl: '.nextColour',
                        prevEl: '.prevColour',
                    }
                });
            }
        });

        /* CHOIX SIZE */
        //On check individuellement chacun des sliders avec each() afin de ne pas fausser le comptage des swiper slide
        $.each($('#selection-offer-content .size_list.swiper-container'), function() {
            if ($(this).find('.swiper-slide').length > 4) {
                new Swiper($(this).get(), {
                    slidesPerView: 5,
                    spaceBetween: 6,
                    navigation: {
                        nextEl: '.nextSize',
                        prevEl: '.prevSize',
                    }
                });
            }
        });
    }

    $('#shad').fadeTo("slow", 1, function () {
        $('#shad').css('display', 'block');
    });

    $("#shad").addClass("actif").on("click", function () {
        closeMultiShad(id);
    });
}

/* LIGHTBOX CLOSING */
function closeMultiShad(id) {

    $('#shad').off('click');

    // Hide modbox and shade and destroy shade's click event

    if (id === "selectionOffertePopup") {
        $('#' + id).fadeOut("slow"); 
    } else {
        $('#' + id).fadeOut("slow").queue(function () {
    
            if ($('#shad').is(":visible")) {
                $('#shad').fadeTo("slow", 0, function () {
    
                    $('#shad').css('display', 'none');
                });
            }
    
            if ($('#shad_abox').is(":visible")) {
                $('#shad_abox').fadeTo("slow", 0, function () {
    
                    $('#shad_abox').css('display', 'none');
                });
            }
    
            $(this).dequeue();
        });
    }

    $('#' + id + ', #shad').fadeOut("slow", 0).queue(function () {

        if ($('#shad').is(":visible")) {
            $('#shad').fadeTo("slow", 0, function () {

                $('#shad').css('display', 'none');
            });
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {

                $('#shad_abox').css('display', 'none');
            });
        }

        $(this).dequeue();
    });
}

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {

    return str.replace(rex_php_tags, '');
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {

    return str.replace(rex_script_tags, '');
}


function stripHTMLComments(str) {

    return str.replace(rex_comments_tags, '');
}

function stripCombo(str) {

    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id)
{
    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val();
    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';

    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '') ? page : 'product',
        is_achat_express : is_wishlist ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data : data,
        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;

                if (data.type_page == 'product') {
                    var $html_main = $(stripCombo(datas.html_main));
                    var color = $("#ligne_couleur").html();
                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var product_refext = $('#product_refext', $html_main).val();
                    var feats_wrapper = $('.productInformationsTxt', $html_main);
                    var otherProduct = $('.otherProducts', $html_main);
                    var publicationPage = $('#publicationPage', $html_main);
                    var avisVerifiesReviews = $('w-netreviews-comments', $html_main);
                    var contents = $('<div />').html($html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                    } else {
                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        
                        // Description compo, livraison, guide tailles
                        $('.productInformationsTxt', '#site_global_wrap').html(feats_wrapper.html());
                        $('.otherProducts', '#site_global_wrap').html(otherProduct.html());
                        
                        /* NETREVIEWS 
                            First switch, ID is in DOM, replace by ID
                            After the first switch replace by tag name
                        */
                        if ($("w-netreviews-comments").length > 0) {
                            $("w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
                        } else {
                            $("#w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
                        }
                        // Reload vuejs netreviews
                        if (typeof wShop !== 'undefined') {
                            // Netreviews stars
                            if (wShop.$refs.wNetreviewsStars != 'undefined') {
                                wShop.$refs.wNetreviewsStars.reload(product_refext);
                            }
                            // Netreviews reviews
                            if (wShop.$refs.wNetreviewsComments != 'undefined') {
                                wShop.$refs.wNetreviewsComments.reload(product_refext);
                            }
                        }

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_'+data.couleurProd).prop('checked', true);

                        $('#publicationPage', '#site_global_wrap').html(publicationPage.html());

                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }

                    if ($(".productInformationsLabel").length > 0) {
                        var productInformationsLabel = $(".productInformationsLabel");
                        var productInformationsTxt = $(".productInformationsTxt");

                        productInformationsLabel = new Swiper('.productInformationsLabel', {
                            slidesPerView: 4
                        });

                        productInformationsTxt = new Swiper('.productInformationsTxt', {
                            slidesPerView: 1,
                            autoHeight: true,
                            thumbs: {

                                swiper: productInformationsLabel
                            }
                        });
                    }

                    // Reload select size
                    $(".productSizeFieldset:not(.fromExpressV2)").on("click touch", function () {
                        var sizesList = $(".sizesList");
                        var shadSelect = $(".shadSelect");

                        sizesList.toggleClass("opened");
                        $(this).toggleClass("actif");
                        shadSelect.addClass("actif");

                        new SimpleBar(sizesList[0]);

                        shadSelect.on("click touch", function () {

                            sizesList.removeClass("opened");
                            $(this).removeClass("actif");
                            $(".productSizeFieldset").removeClass("actif");
                        });
                    });

                    // On scroll en haut de la page dans le cas d'un regroupement
                    if (!data.is_achat_express_v2) {
                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }

                    if (wShop.$refs.wAlertestockForm) {
                        wShop.$refs.wAlertestockForm.reloadBloc += 1;
                    }
                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));

            } else {
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {

            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                if($(".productThumbs_test").length > 0) {
                    var productThumbs = new Swiper('.productThumbs_test', {
                        slidesPerView: 2,
                        slidesPerColumn: 2,
                        slidesPerGroup: 2,
                        navigation: {
                            nextEl: '.swiper-button-nextFP',
                            prevEl: '.swiper-button-prevFP',
                        },
                    });
                }
                /* Fin reinitialisation sliders visus */

                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {

                    var jqzoom = $(this).next('#jqzoom');

                    if (jqzoom.length > 0) {

                        $(this).height(jqzoom.height());

                        if (document.getElementById('visual_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                            }, 250);
                        }
                    }
                });

                $(window).on('resize', function () {

                    more_vis.each(function () {

                        var jqzoom = $(this).next('#jqzoom');

                        if (jqzoom.length > 0) {

                            $(this).height(jqzoom.height());
                        }
                    });
                });

                /* PRODUCT SLIDERS */
                var mainSlideFp = $(".mainSlideFp");
                var thumbSlideFp = $(".thumbSlideFp");

                if (mainSlideFp.length) {

                    thumbSlideFp = new Swiper('.thumbSlideFp', {

                        slidesPerView: 3
                    });

                    mainSlideFp = new Swiper('.mainSlideFp', {

                        slidesPerView: 1,
                        effect: 'fade',
                        thumbs: {

                            swiper: thumbSlideFp
                        },
                        navigation: {

                            prevEl: '.prevSlideFp',
                            nextEl: '.nextSlideFp',
                        }
                    });
                }

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 500);
            }, 250);

            heads.each(function(){
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);
            $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);
            checkProductInWishlist();

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#product_addcart").appendTo('.product_info');
                $("#addToWishlistButton").on("click", addToWishlist);
            }
        }
    });
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {

    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css('display');

    if (!loader.parent().hasClass('loading')) {

        loader.parent().addClass("loading");

        switch (action) {

            case 0:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuthTop, 1, path_relative_root);
                break;

            case 1:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwdTop, 1, path_relative_root);
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass('loading');
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuth, 1, path_relative_root);
                break;

            case 5:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwd, 1, path_relative_root);
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (checkAllForm(tabErrorGrattez, 0, path_relative_root, id_form)) {

                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

/* DECONNEXION */
function ajaxDeconnect(path_relative_root){

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

/* FORGOTTEN PASSWORD */
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({

        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({

            opacity: 1
        }, 600);
    });
};

/* BACK STEP FORGOTTEN PASSWORD */
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    passWd.animate({

        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({

            opacity: 1
        }, 600);
    });
};

/* Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php) */
function openCloseMessage(elem, msg_open, msg_close) {

    var id = elem.attr('id');
    var mId = id.substring(id.indexOf('_')+1);

    if(!elem.hasClass('actif')) {

        $('.message_detail').slideUp();
        $('.btn_ouvrir_message').not(elem).removeClass('actif');
        $('.btn_ouvrir_message').not(elem).text(msg_open);

        if( $('#msg_'+mId).html() == '' ) {

            $.ajax({

                type: 'post',
                data: { mId: mId, type: 1 },
                url: path_relative_root+create_link('message_detail'),
                success: function( response ){

                    $('#msg_'+mId).html(response);
                    $('#msg_'+mId).slideDown();
                    elem.addClass('actif');
                    elem.text(msg_close);
                }
            });
        } else {

            $('#msg_'+mId).slideDown();
            elem.addClass('actif');
            elem.text(msg_close);
        }
    } else {

        $('.message_detail').slideUp();
        elem.removeClass('actif');
        elem.text(msg_open);
    }
}

/* Add body class for search event */
$("#search_home").on('input', function() {

    var value = $("#search_home").val();

    if(value.length >= 3){

        $('body').addClass('search_in');
    } else {

        $('body').removeClass('search_in');
    }
});

/**
 * Peremt d'afficher ou non le bouton d'ajout au panier
 * @author Aurélien Renault
 * @since  2019-11-08
 */
function btnAddBasketManage() {

    if (window.json_colisages !== undefined && window.json_colisages !== null) {
        var color_id = $('#couleurProd').val();
        var size_id = $('#tailleProd').val();

        // On regarde si on a assez de stock pour la couleur et la taille choisie
        if (window.json_colisages[color_id] !== undefined) {
            if (window.json_colisages[color_id][size_id] !== undefined) {
                if (window.json_colisages[color_id][size_id] === true) {
                    $('#bloc_add_basket').css('display', 'block');
                    $('#bloc_add_alert').css('display', 'none');
                } else {
                    $('#bloc_add_basket').css('display', 'none');
                    $('#bloc_add_alert').css('display', 'block');
                }
            }
        }
    }
}

/* ACHAT EXPRESS */
function ajax_achat_express(reference, wishlist, idprod, code_couleur) {
    var arg = '';

    if (wishlist !== undefined || wishlist) {

        arg = '&wishlist=true';
    }
    var self = $(this);

    $.ajax({
        type: 'get',
        url: path_relative_root + 'ajax_achat_express.php?ref=' + reference + '&couleur=' + code_couleur + arg,
        success: function (response) {
            var $lighbox;
            if ($('#lightbox_achat_express').length === 0) {

                $('body').append('<div id="lightbox_achat_express" class="lightbox"></div>');
            }

            $lighbox = $('#lightbox_achat_express');
            $lighbox
                .html('<div class="express_response"><div class="close" onclick="closeMultiShad(\'lightbox_achat_express\')"></div>' + response + '</div>')
                .appendTo('body');
            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', idprod, path_relative_root);

            /* Function to simulate the behavior of a select */
            $(".productSizeFieldset").on("click touch", function () {

                var sizesList = $(".sizesList");
                var shadSelect = $(".shadSelect");

                sizesList.toggleClass("opened");
                $(this).toggleClass("actif");
                shadSelect.addClass("actif");

                new SimpleBar(sizesList[0]);

                shadSelect.on("click touch", function () {

                    sizesList.removeClass("opened");
                    $(this).removeClass("actif");
                    $(".productSizeFieldset").removeClass("actif");
                });
            });

            // event sur le bouton d'ajout à la wishlist
            if (document.getElementById('addToWishlistButton') != null && $._data(document.getElementById('addToWishlistButton'), "events").click == undefined) {
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            // Event sur les pierres et les finitions
            if ($(".toggleSelection").length > 0) {

                $(".toggleSelection").on("click", function () {

                    $(this).next(".selectionWrapper").slideToggle();
                });
            }

            // Pour les event des tooltips "Veuillez selectionner une taille"
            eventButton();
            setTimeout(function () {

                checkSubmitState();
            }, 500);
        }
    });
}

/* PAGINATION RAYON */
function paginate(page) {
    if (document.getElementById('filter_sticky')) {
        var form = document.getElementById('filter_sticky'),
            total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),
            pagination = $('.pager_wrapper.pager_wrapper_rayon'),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        page = page || 1;

        $.ajax({
            url: path_relative_root + 'ajax_reload_pagination.php',
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {
                pagination.each(function () {
                    var html = '';
                    if (nb_prod != 1000 && nb_prod < total_element) {
                        html = $('<div class="see_all_product" onclick="seeXProduct(1000)">' + translate('see_all', total_element) + '</div><label>' + translate('pages') + '</label>' + res);
                    } else if (total_element > nb_prod_default) {
                        html = $('<div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ')">' + translate('see_x_product_by_page', nb_prod_default) + '</div>');
                    } else {
                        html = '';
                    }

                    $(".filter_page", $(this)).html(html);
                });

                loader_voirTout();
            }
        });
    }
}

function loader_voirTout(){
    $('.filter_page').prepend('<div class="loader_voirTout"></div>');
    $('.loader_voirTout').hide();
}
loader_voirTout();

function seeXProduct(nb_product) {

    // On definie le nombre de produit a charger à 1000
    $('#nb_prod').val(nb_product);

    $('.see_all_product').hide();
    $('.loader_voirTout').show();


    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(0);
    } else {

        generateNewBlocProd(0);
    }

}

/* RELOAD AFTER FILTERING */
var bloc_prod_xhr;

var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        output = {},
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.value !== '') {

                if (output[elm.name] === undefined) {

                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {

            if (elm.value !== '') {

                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {

        if (output.hasOwnProperty(key)) {

            if (output[key].constructor === Array) {

                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {

        form.nb_prod.value = nb_product;
    } else {

        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {

        form.page.value = page * nb_product;

        is_from_page = true;
    }

    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Si le prix existe
    if ($('#price_min').length > 0) {
        // Récupération du prix
        output.prix = form.getValue('price_min', parseFloat) + '~' + form.getValue('price_max', parseFloat);
    }

    if (bloc_search_xhr) {

        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_search.php',
        type: 'get',
        data: output,
        success: function (res) {

            var new_elm = document.createElement('div'),
                old_elm = document.getElementById('scroll_items'),
                content, itm_length;

            if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split('~');

            itm_length = parseFloat(res[1]);

            if (content !== '') {

                new_elm.id = 'list_item';
                new_elm.className = 'items_wrapper';
                new_elm.innerHTML = content;

                if (!isNaN(page)) {

                    new_elm.setAttribute('data-p', (page * nb_product));
                }

                old_elm.innerHTML = '';

                document.getElementById('nb_articles').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                old_elm.appendChild(new_elm);

                if (window.lazyload !== undefined) {

                    lazyload.init();
                }

                /* LEGACY */

                var js_compteur = $('.vf_countdown').length;
                var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                        }
                    } catch (e) {}
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                        eval(js_encart);
                    } catch (e) {}
                }
                /* END LEGACY */
            }
        },
        complete: function () {

            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $('#content_obj').position().top - $('#site_head_wrap').height()
                }, 200);
            }, 250);
        }
    });

    $("#js-resetFilter").on("click", deleteAllFilter);
}

function paginationGoToPage(page) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc);
    }
}


function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function slideDown(parent, classInput, classOption) {

    var newActifInput = $(this);
    if (!newActifInput.hasClass('actif')) {
        // On replie
        var actifOption = $('.' + classOption + '.actif', parent);
        actifOption.slideUp(400).removeClass('actif');

        var actifInput = $('.' + classInput + '.actif', parent);
        actifInput.removeClass('actif');

        // On deplie
        newActifInput.slideDown(400).addClass('actif');

        var newActifOption = $(this).next();
        newActifOption.slideDown(400).addClass('actif');
    } else {

        // On replie
        newActifInput.removeClass('actif');

        var newActifOption = $(this).next();
        newActifOption.slideUp(400).removeClass('actif');
    }
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {

    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {

            if (sizes_tab.hasOwnProperty(size_id)) {

                size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];

                if (!is_disabled) {
                    size_box.removeClass('disabled');
                } else {
                    size_box.addClass('disabled');
                }

            }
        }
    }
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {

    var couleurs_id = 'couleurProd';
    var tailles_id = 'tailleProd';

    if (elementID != 'liste_qte') {
        couleurs_id += '_' + idProduit;
        tailles_id += '_' + idProduit;
    }

    var listQte = $('#' + elementID);
    var alternative_listQte = $('#liste_qte_spe');

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html('<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' + parentPath + 'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>');

            var couleurs = (($('#' + couleurs_id).length) ? $('#' + couleurs_id).val() : 'none');
            var tailles = (($('#' + tailles_id).length) ? $('#' + tailles_id).val() : 'none');
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = 'none';
            var tailles = 'none';
        }

        var response = ajax_file(parentPath + 'ajax_liste_qte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + '&produit_lot_nb=' + $("#produit_lot_nb").val() + '&suff=' + (suff || ''));

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $('#qteProd');
            var regexp_epuise = /Stock /;
            var txt_stock = $('#qteProd>option:selected').text();

            if (select_stock.length > 0 && txt_stock.match(regexp_epuise) != null) {

                $("#add_alert").show();
                $("#add_basket").hide();

            } else {

                $("#add_alert").hide();
                $("#add_basket").show();

            }

        }

    }
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id)
{
    var control;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() !== undefined && !is_wishlist);

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    var productSizeFieldset = $(".productSizeFieldset");
    var sizesList = $(".sizesList");
    var shadSelect = $(".shadSelect");

    productSizeFieldset.removeClass("actif");
    sizesList.removeClass("opened");
    shadSelect.removeClass("actif");

    if (this.checked) {
        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        var id= form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        $('.choosenSize').html(this.dataset.nom);

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        // En fonction de la couleur on regarde si le produit est disponible

        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id, is_wishlist);
        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            checkProductInWishlist();
        }
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist)
{
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);

    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();
    var plnb = form.getValue('produit_lot_nb');

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            if (res.stock_tampon == 0) {
                $(".eclat_product_nouveaute_fiche_produit").show();
                $("#bloc_add_alert" + elem).show();
                $("#prod_info" + elem + " .rollover_left").hide();

                if (!is_achat_express) {
                    $("#bloc_add_basket").hide();
                }

                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');
                $('.expressBuy').removeClass('loading');
            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 3) {
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if (is_achat_express) {
                    waitingAddToBasket(event, form, '', itm_rr_id);
                    $('.expressBuy').removeClass('loading');

                    // Show bloc colors when we add product to basket
                    $('#ligne_pointure' + elem).hide();
                    $('#ligne_couleur' + elem).show();
                    $("#prod_info" + elem + " .bloc_add_color").show();
                } else {
                    if (res.produit_precommande != "oui") {
                        $(".eclat_product_nouveaute_fiche_produit").hide();
                        $("#bloc_add_alert").hide();
                        $("#bloc_add_basket").show();
                    }
                }
            };
            pushIntoDatalayer(res.dataLayer_object);
            $('.expressBuy').removeClass('loading');
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id)
{
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() !== undefined && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', '', itm_rr_id);
        } else {
            // On regarde si le produti est dejà dans la wishlist
            if (!is_achat_express_v2) {
                var in_wishlist = checkProductInWishlist();
                changeBtnAddWishlist(in_wishlist, prod_id);
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            changeBtnAddWishlist(in_wishlist);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

/**
 * Permet d'initialiser un slider de prix
 * @param slider_name
 */
function initPriceSlider(slider_name) {

    // Le slider pour le prix
    var slider = $(slider_name);
    var min = slider.data('min');
    var max = slider.data('max');
    var min_selected = $(".min_input", slider.parent());
    var max_selected = $(".max_input", slider.parent());
    slider.slider({
        range: true,
        min: min,
        max: max,
        values: [
            min_selected.val(),
            max_selected.val()
        ],
        slide: function (event, ui) {
            if (ui.handleIndex === 0) {
                $(".min", $(this).parent()).html(ui.value);
                min_selected.val(ui.value);
            } else {
                $(".max", $(this).parent()).html(ui.value);
                max_selected.val(ui.value);
            }
        },
        stop: function (event, ui) {
            generateNewBlocProd.call(document.getElementById('filter_form'), 0)
        }
    });
}

function updateFilter(tab_response, page, parentPath, type, idObj, no_price_range_update) {
    if (tab_response[0] != '') {
        var bloc_photo = tab_response[0];
        document.getElementById('list_item').innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function () {
            $(this).hover(function () {
                $(this).children('.visu_product_slider').children('.visu_product_slider_hover').show();
            }, function () {
                $(this).children('.visu_product_slider').children('.visu_product_slider_hover').hide();
            });
        });

        var nb_prod = tab_response[4];

        if (document.getElementById('nb_prod_numeric')) document.getElementById('nb_prod_numeric').innerHTML = nb_prod;
        if (document.getElementById('label_nb_prod_numeric')) {
            if (nb_prod == '')
                document.getElementById('label_nb_prod_numeric').style.display = 'none';
            else
                document.getElementById('label_nb_prod_numeric').style.display = 'block';
        }

        if (typeof (page) == 'undefined') {

            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update) {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);


                $("#slider-range").slider("option", {
                    min: parseInt(prix_min),
                    max: parseInt(prix_max),
                    values: [parseInt(prix_min), parseInt(prix_max)]
                });

                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css('left', '0%');
                $(".ui-slider-range").next().css('left', '0%');
                $(".ui-slider-range").next().next().css('left', '100%');
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split('~');
            var cnt_color = tab_color_tmp.length;

            if (tab_color_tmp[0] != '') {

                txt_color = ajax_file(parentPath + 'ajax_sprintf.php?arg1=recherche_couleurs');

                var str_color = '';

                for (var j = 0; j < cnt_color; j++) {

                    var detail_color = tab_color_tmp[j].split('|');
                    var class_color = 'btn_select_off';

                    if (tab_color.length > 0) {
                        if (in_array_js(detail_color[0], tab_color)) {
                            class_color = 'btn_select';
                        }
                    }
                    str_color += '<div id="couleur_' + detail_color[0] + '" class="left_menu_obj ' + class_color + ' couleur_' + detail_color[0] + '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' + detail_color[1] + /* ' (' + detail_color[2] + ')*/ '</div></div>';
                }

                $("#bloc_couleur").show().find(".selected_options").html(str_color);
            } else {
                $("#bloc_couleur").hide().find(".selected_options").html('');
            }
            // size
            var size = tab_response[8];

            var tab_size_tmp = size.split('~');
            var cnt_size = tab_size_tmp.length;

            if (tab_size_tmp[0] != '') {

                txt_size = ajax_file(parentPath + 'ajax_sprintf.php?arg1=recherche_taille');

                var bloc_size = '<div class="select_recherche">' + txt_size + '</div><div class="selected_options"></div>';

                var str_size = '';

                for (var j = 0; j < cnt_size; j++) {

                    var detail_size = tab_size_tmp[j].split('|');
                    var class_size = 'btn_select_off';

                    if (tab_taille.length > 0) {
                        if (in_array_js(detail_size[0], tab_taille))
                            class_size = 'btn_select';
                    }

                    str_size += '<div id="taille_' + detail_size[0] + '" class="left_menu_obj ' + class_size + '"><div class="f_left"></div><div class="f_left txt_menu">' + detail_size[1] + '</div></div>';

                }

                $("#bloc_taille").show().find(".selected_options").html(str_size);
                var windowW = $(window).height();
                if (windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }

            } else {
                $("#bloc_taille").hide().find(".selected_options").html('');
            }
        }
    } else {
        $('#list_item').html(ajax_file(parentPath + 'ajax_sprintf.php?arg1=no_response_search'));
        $('#pagination_haut').html('');
        $('#pagination_bas').html('');
    }
    $(".left_menu_obj").on('click touchend', filterBoxesToggle);
    var windowW = $(window).height();
    if (windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }
    $('#page').val(0);
}

function deleteAllFilter() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();
                elm.value = 0;
                elm.checked = false;
            }
        }
    });

    $('.slider-range div.ui-slider-range').css('left','0');
    $('.slider-range div.ui-slider-range').css('width','100%');
    $('.priceRangeDisplay div.min').html($('[data-min]').data('min'));
    $('.priceRangeDisplay div.max').html($('[data-max]').data('max'));
    $('#choice_tri').val('0');
    $('.selected_options a').removeClass('actif');

}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var loader;

    if (!is_achat_express) {
        evt.preventDefault();
    }

    if (from === undefined) {
        from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        btnWrapper = $(this).parent();

        btnWrapper.addClass("loading");
    } else {
        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }
    if (is_lightbox_specifique == 'true') {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var prix = '';
    if (document.getElementById(prix_id)) {
        prix = document.getElementById(prix_id).value;
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, prix, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);
    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd';
        } else {
            omnitureEvent = 'scAdd';
        }

        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        // DATALAYER V2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];
            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }
            pushIntoDatalayer(dl_objet);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

        $("#cart_top").html(response_basket);

        $("#lightbox_achat_express, .lightbox_achat_express, #shad").removeClass("actif");

        newshowmodal(produit_id, is_not_produit);
        $('.btnWrapper').removeClass('loading');
    } else {

        if (loader) {

            $('.btnWrapper').removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {
    if (i != '') {
        i = '_' + i;

        prixU = $('#prixU' + i).val();
    }

    var couleurs = $('#couleurProd' + i);
    var tailles = $('#tailleProd' + i);
    var res = '';
    var flagError = false;

    if ($('#lightbox_achat_express').length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == '') {
        $('#sizeerror').slideDown();

        return false;
    } else if (couleurs && couleurs.val() == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none')
            idCouleur = couleurs.val();

        if (tailles && tailles.val() != 'none')
            idTaille = tailles.val();

        if (idCouleur === undefined) {
            idCouleur = idElCouleur;
        }

        if (idTaille === undefined) {
            idTaille = idElTaille;
        }

        var array_response = [];
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match('^[0-9]')) {

                if (designation == 1) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';

                }

            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {

                if (document.getElementById(idElTaille).selectedIndex) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;

                } else if (lastTaille != '') {

                    designation += ' - Taille ' + lastTaille;

                }

            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {

                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {

                    designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                } else if (lastCouleur != '') {

                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == 'false') {
                $('#size_' + idTaille).addClass('disabled');
                alert(translate('product_no_stock_2'));
                return false;
            }
        }

        return array_response;
    }
}

/* Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible */
function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(translate('js_error_couleur'));
        return;
    }

    if (!$(".w-alertestock-form").length) {
        new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (iserrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {

        $('.bloc_add_alert_form').slideDown('fast');
    }
}

function showsendfriendmodal(product_id, path_web, type) {
    var elname = (type === 'look_basket') ? 'modbox' : 'sendfriendbox';
    var headHeight = $("#site_head_wrap").innerHeight();

    if (type !== 'look_basket') {

        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');
        if (psnok) {

            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if (psfok) {

            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        $("html, body").animate({
            scrollTop: headHeight
        }, 750);
        openMultiShad('sendfriendbox');

    } else {

        $("html, body").animate({
            scrollTop: headHeight
        }, 750);
        show_lightbox(elname);
        $('.loader', '#bloc_add_basket').hide();
    }
}



function closesendfriendmodal() {

    closeMultiShad('sendfriendbox');

    document.getElementById('sendfriend_alert_mail').style.display = "none";
    document.getElementById('sendfriend_form_to').style.display = "block";
    document.getElementById('sendfriend_form_froms').style.display = "block";
    document.getElementById('sendfriend_form_buttons').style.display = "block";
    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";

    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "visible";
        }

    }
}

function closesendfriendmodalfromconfirm() {

    closeMultiShad('sendfriendbox');

    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.getElementById('sendfriend_form_to').style.display = "block";
    document.getElementById('sendfriend_form_froms').style.display = "block";
    document.getElementById('sendfriend_form_buttons').style.display = "block";
    document.getElementById('sendfriend_alert_mail').innerHTML = "";
    document.getElementById('sendfriend_alert_mail').style.display = "none";

    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "visible";
        }

    }

}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {

    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte
        },
        url: path_relative_root + 'ajax_removeBasketProd.php',
        success: function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');

                return;
            }

            $('#cart_top').html(res.datas.html);
            $('#show_top_cart').addClass('appear');
        }
    });
}

/**
 * Lancer de l'ajout au panier
 * @param products
 * @param type
 * @param root
 * @param id_produit
 * @param is_not_produit
 */
function newshowmodal(products, type, root, id_produit, is_not_produit) {

    $('.topCartRollover').addClass('opened');
    $('#shad').addClass('actif')

    window.setTimeout(function () {

        $('.topCartRollover').removeClass('opened');
        $('#shad').removeClass('actif')

    }, 5000);
}

/* PRODUCT PAGE ZOOM */
function zoomImg(slide) {

    $('#zoom_box_wrapper').css('opacity', 0);

    window.setTimeout(function () {

        var productLightboxVisu = new Swiper('.productLightboxVisu', {

            slidesPerView: 3,
            spaceBetween: 0,
            navigation: {
                nextEl: '.zoomProdNext',
                prevEl: '.zoomProdPrev'
            }
        });

        if (slide == null) {

            productLightboxVisu.slideTo(productMainVisu.activeIndex);
        } else {

            productLightboxVisu.slideTo(slide);
        }
    }, 50);

    window.setTimeout(function () {

        $('#zoom_box_wrapper').css('opacity', 1);
    }, 350);

    openMultiShad('zoomBox');
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    var container = document.getElementById(elementID);

    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        } else {
            container.innerHTML = '';
            return false;
        }
    } else
        return false;
}

var global_id_transporteur = '';

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var numDIVObjet = document.getElementById("popup_numero_retour");
        // Affichage de la popup de retour
        numDIVObjet.classList.add('actif');

        $("#return_products_form").lionbars();
        $("#shad").addClass('actif');
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp,idOrderTransp) {

    var numRetour = "";
    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function() {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0  && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }

            data = $('#return_products_form').serialize();
            data += '&idOrder='+idOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;

            $.ajax({
                url : parentPath + 'ajax_create_etiquette.php',
                type : 'post',
                data : data,
                success : function (res) {
                    location.reload();
                }
            });

        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }

    }
}

function achatExpressV2(product_id, regroup_ref_id) {

    $('.productSizeFieldset').hide();
    $('.productColorFieldset').show();
    $('.bloc_add_color').show();

    $('#ligne_pointure_' + product_id + regroup_ref_id).show();
    $('#ligne_couleur_' + product_id + regroup_ref_id).hide();
    $("#prod_info_" + product_id + regroup_ref_id + " .bloc_add_color").hide();

    if($('#size_list .choices_list ').length < 5){
        setTimeout(function(){
            var sizeSwiper = new Swiper('#size_list_' + product_id + regroup_ref_id, {
                slidesPerView: 9,
                freeMode: true,
                scrollbar: {

                    el: '.swiper-scrollbar',
                    hide: false,
                },
                navigation: {
                    nextEl: '.arrow_next_' + product_id,
                    prevEl: '.arrow_prev_' + product_id,
                },
            });
        }, 200);
    } else {
        setTimeout(function(){
            var sizeSwiper = new Swiper('#size_list_' + product_id + regroup_ref_id, {
                slidesPerView: 7,
                freeMode: true,
                scrollbar: {

                    el: '.swiper-scrollbar',
                    hide: false,
                },
                navigation: {
                    nextEl: '.arrow_next_' + product_id,
                    prevEl: '.arrow_prev_' + product_id,
                },
            });
        }, 200);
    }
}

function sendMailAlertStock (type_alert, form, itm_rr_id)
{
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id= form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert,
        $verif_mail = checkForm('mail', document.getElementById('mail_alerte_stock' + elem), 'login_email');

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $.ajax({
            type : 'post',
            url : path_relative_root + create_link('ajax_mail_alert_stock'),
            data : {
                email : $email,
                produit_id : $produit_id,
                taille_id : $taille_id,
                couleur_id : $couleur_id,
                type_alert : $type_alert
            },
            success : function(response) {
                $('.alert_stock').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    "event": "stockAlert",
                });
            },
            fail : function () {
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

$(function () {

    /* ACTIVATE RIGHT PART IN CONNEXION */
    $(".wrapper_login").on("click touch", function () {

        $(".wrapper_login").removeClass("actif");
        $(this).addClass("actif");
    });

    $("body.cms_page  #breadcrumbs a").on('click', function(event){
        event.preventDefault();
    });

    $(".bloc_lightbox_preco").on("click",function() {
        if (!$(this).hasClass("actif")) {
            $(".bloc_lightbox_preco.actif").removeClass("actif");
            $(this).addClass("actif");
        }
    });
});
function process_type_preco() {
    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + "ajax_choose_preco.php",
        success: function(response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }
                var index_conveyor =
                    typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function(i, item) {
                    array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                    array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                    array_transporteurs_datas[i]["fdp"]["format"] =
                        item["format"];
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                        i +
                        "] .pricetag"
                    ).html(item["format"]);
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                        i +
                        "] .price_tag"
                    ).html(item["format"]);
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
                $("#lightbox_preco").css("display", "none");
            }
        },
    });
}

function verif_mail(elementID, fieldname, is_spe) {

    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var mail = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$", "gi");

    path = '';
    if (typeof (path_relative_root) != 'undefined') {
        path += path_relative_root;
    }

    if (mail.exec(objElement.value) == null) {
        if (typeof (is_spe) == 'undefined') {
            var translations = ajax_file(path + 'ajax_sprintf.php?arg0=%s|%s' +
                '&arg1=' + fieldname +
                '&arg2=js_wrong_input_mail'
            );

            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML = '<p class="title_toolltip">' + type_champ + ' :</p>' + type_message;
        }
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }

    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

var timer;
function checkForm(type, elem, fieldname, from, blockMenuTopConnexionInfobulle ) {
    if(blockMenuTopConnexionInfobulle == undefined) {blockMenuTopConnexionInfobulle = 0;};
    if( from != 'click' ){
        elementID = elem.id;
    }else{
        elementID = elem;
    }
    var already_mail = false;
    if( elem == 'clientCivilite' ) elementID = elem;
    switch(type) {
        case"prix":
            var flag = verif_price(elementID,fieldname);
            break;
        case 'alpha':
            var flag = verif_alpha(elementID,fieldname,false);
            break;
        case 'alpha_optional':
            var flag = true;
            if( document.getElementById(elementID).value != '' ) flag = verif_alpha(elementID,fieldname,false);
            break;
        case 'alphanum':
            var flag = verif_alphanum(elementID,fieldname,false);
            break;
        case 'alphanumadresse':
            var flag = verif_alphanumadresse(elementID,fieldname,false);
            break;
        case 'alphanum_optional':
            var flag = true;
            if( document.getElementById(elementID).value != '' ) {
                if(fieldname.indexOf('|')!=-1){
                    length = fieldname.substring(fieldname.indexOf('|')+1);
                    fieldname = fieldname.substring(0,fieldname.indexOf('|'));
                    flag = verif_alphanum(elementID,fieldname,true,length);
                }
                else flag = verif_alphanum(elementID,fieldname,false);
            }
            break;
        case 'same':
            elemCmpID = elementID.substring(0,elementID.length-1);
            var flag = verif_alphanum(elementID,fieldname,false);
            if( flag ) flag = verifSame( elemCmpID, elementID, fieldname );
            break;
        case 'same_optional':
            var flag = true;
            elemCmpID = elementID.substring(0,elementID.length-1);
            if( document.getElementById(elementID).value != '' || document.getElementById(elemCmpID).value != ''  ) {
                var flag = verif_alphanum(elementID,fieldname,false);
                if( flag ) flag = verifSame( elemCmpID, elementID, fieldname );
            }
            break;
        case 'mail':
            var flag = verif_mail(elementID, fieldname);
            break;
        case 'mail_log':
            var flag = verif_mail(elementID,fieldname);
            if( flag ) {
                var action = $("#" + elementID).closest('form').attr('action');
                var params = 'mail='+document.getElementById(elementID).value+ '&action='+action;
                flag = ajax_file_post(path_relative_root + 'ajax_email_exist.php', params);
                var flag_ret = flag;
                if( flag == '1' || flag == '2' ) flag = false;
                else flag = true;
                if( !flag ) {
                    var objElement = document.getElementById(elementID);
                    var objLabel = document.getElementById(elementID + 'Label');
                    var erreurText = document.getElementById('erreur_msg');
                    if ( flag_ret == '1')
                        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=js_already_mail');
                    else
                        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=login_erreur_17');
                    objElement.className = 'inputErr';
                    if(objLabel){
                        objLabel.className = 'labelErr';
                    }
                    already_mail = true;
                }
            }
            break;
        case 'mail_log_info':
            var flag = verif_mail(elementID,fieldname);
            if( flag && ( document.getElementById(elementID).value != document.getElementById('old_clientMail').value ) ) {
                flag = ajax_file('ajax_email_exist.php?mail='+document.getElementById(elementID).value);
                if( flag == '1' ) flag = false;
                else flag = true;
                if( !flag ) {
                    var objElement = document.getElementById(elementID);
                    var objLabel = document.getElementById(elementID + 'Label');
                    var erreurText = document.getElementById('erreur_msg');
                    erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_already_mail_short');
                    objElement.className = 'inputErr';
                    objLabel.className = 'labelErr';
                    already_mail = true;
                }
            }
            break;
        case 'mail_verif':
            elemCmpID = elementID.substring(0,elementID.length-1);
            var flag = verif_mail(elementID,fieldname);
            if( flag ) flag = verifSame( elemCmpID, elementID, fieldname );
            break;
        case 'mail_optional':
            var flag = true;
            if( document.getElementById(elementID).value != '' ) flag = verif_mail(elementID,fieldname);
            break;
        case 'radio':
            var flag = verif_radio(elementID,fieldname);
            break;
        case 'select_date':
            var flag = verif_selected_date(elementID,fieldname);
            break;
        case 'phone':
            //var flag = verif_num( elementID,fieldname,10 )
            var flag = true;
            if( flag ) flag = verif_telephone(elementID,fieldname);
            break;
        case 'mobile':
            var flag = true;
            flag = verif_num( elementID,fieldname,10 )
            if( flag ) flag = verif_telephone(elementID,fieldname, true);
            break;
        case 'cp':
            var flag = true;
            break;
        case 'no_empty':
            var flag = verif_empty(elementID,fieldname);
            break;
        case 'borne_code' :
            var flag = verif_num(elementID,fieldname,6);
            break;
        case 'number' :
            var flag = verif_num(elementID,fieldname,5);
            break;
        case 'is_valid' : // Peremt de specifier que le champs sera toujours valid
            var flag = true;
            break;
    }

    var style = '';
    if( from == 'click' || elem == 'clientCivilite' ) {
        if( type == 'select_date' ) {
            elem = document.getElementById(elem+'J');
            if( elementID == 'newsletterDatenais' )
                style = 'style="margin-left:45px;"';
            else if(  elementID == 'clientDatenais') {
                var nav = navigator.appVersion;
                style = 'style="margin-top:5px;margin-left:95px;"';
                if ( nav.indexOf('MSIE') >= 0 )
                    style = 'style="margin-left:53px;"';
            } else if (elementID == 'garantieDateAchat') {
                style = 'style="margin: 5px 0 0 -10px"';
            }
            elementID += 'J';
        }
        else if( type == 'radio' ) {
            elem = document.getElementById(elem+'M');
            elementID += 'M';
            style = 'style="margin-left:307px;margin-top:4px;"';
        }
        else elem = document.getElementById(elem);
    }
    if( elementID == 'adresseTitre' && $.browser.webkit )
        style = 'style="margin:-22px 0 0 217px;"';

    if (!flag) {
        if (blockMenuTopConnexionInfobulle != 1) {

            if ($('#' + elementID).closest('.formItem_container, .input_container').children('img').length == 0) {
                $('#' + elementID).closest('.formItem_container, .input_container').append('<img ' + style + ' src="'+path_relative_root+'img/picto_panneau.png" alt="" class="img_error" />');
            }
            elem_img = $("#"+elementID).closest('.formItem_container, .input_container').children('img')[0];
        } else {
            document.getElementById(elem.id+'Err').style.display = 'table-row';
            var nav = navigator.appVersion;
            if ( nav.indexOf('MSIE 7') >= 0 ) document.getElementById(elem.id+'Err').style.display = 'block';
            if( $("#bloc_erreur").css('display') == 'table-row' || $("#bloc_erreur").css('display') == 'block'|| $("#bloc_erreur").css('display') == 'table' ) {
                $("#bloc_erreur").css('display','none')
                clearTimeout(timer);
            }
        }
    }

    if( !flag && from != 'click' ) {
        if (blockMenuTopConnexionInfobulle != 1) {

            if( from == 'spe' && !already_mail ) document.getElementById('erreur_msg').innerHTML = document.getElementById(elementID + 'Err').innerHTML;
            if(!jQuery.browser.msie) $("#bloc_erreur").fadeIn(400);
            else document.getElementById("bloc_erreur").style.display = 'block';
            document.getElementById('bloc_erreur').style.position = 'absolute';
            var nav = navigator.appVersion;

            window.clearTimeout(timer);
            timer = document.getElementById('bloc_erreur').style.display = "none";
        }
    }
    else if( from != 'click' ) {
        window.clearTimeout(timer);
        timer = document.getElementById('bloc_erreur').style.display = "none";
    }
    if( !flag ) {

    }
    else {
        if (blockMenuTopConnexionInfobulle == 1) {
            document.getElementById(elem.id+'Err').style.display = 'none';
        }
        if( $("#"+elementID).closest('.input_container').children("img").length>0 ) {
            $("#"+elementID).closest('.input_container').children("img").remove(".img_error");
        }
        if( $("#"+elementID).hasClass('inputErr')) {
            $("#"+elementID).removeClass('inputErr');
            //Au cas ou elle existerait deja, pour etre sur de ne l'ajouter qu'une seule fois
            $("#"+elementID).removeClass('inputForm');
            $("#"+elementID).addClass('inputForm');
        }
        if( $("#"+elementID+'Label').hasClass('labelErr')) {
            $("#"+elementID+'Label').removeClass('labelErr');
            //Au cas ou elle existerait deja, pour etre sur de ne l'ajouter qu'une seule fois
            $("#"+elementID+'Label').removeClass('labelForm');
            $("#"+elementID+'Label').addClass('labelForm');
        }
        if( $("#bloc_erreur").css('display') == 'table-row' || $("#bloc_erreur").css('display') == 'block' ) {
            $("#bloc_erreur").css('display','none');
            clearTimeout(timer);
        }
        if( from != 'spe' && elem != 'clientCivilite' ) {
            elem.setAttribute('onfocus',function(){})
            elem.onfocus=function(){}
        }
    }
    return flag;
}

/* FUNCTION PUSH INTO DATALAYER */

function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}


/* PROD ASSOCS DATA LAYERS */

function dataLayer_associated_product () {

    if (window.$('.otherProduct')) {

        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

// Alters item quantity in cart
function changeQte(type) {

    // if no stock, do not reload the page. Show an error message
    if(type=="plus" && $(this).find("button.change_qte.moreQte.no_stock").length > 0) {
        $(this).find("p.max_stock").addClass("show_msg");
        return false;
    }

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                cart = Translator.translate('mon_panier');
                var total = $('#cart_top .cart').attr('data-cart'),
                    text;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                }

                $('.cart_main_title.basket').html(cart + ' <span class="nb_products">(' + total + ' ' + text + ')</span>');
                $('#cart_total .nbProdCartTotal').html('<span class="nbProdCartTotal">' + total + ' ' + text + '</span>');
            }
        });
    }
}