// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {

    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                'Msxml2.XMLHTTP.6.0',
                'Msxml2.XMLHTTP.3.0',
                'Msxml2.XMLHTTP',
                'Microsoft.XMLHTTP'
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch(error) {};
            };

        } else {
            xhr = new XMLHttpRequest();
        };

    } else {
        return null;
    };
    return xhr;
};

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
     if(window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
     else if(window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return(false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if(xhr_object.readyState == 4) return(xhr_object.responseText);
    else return(false);
}

function ajax_file_post(url, params) {
    if(window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if(window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr_object.send(params);

    if(xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
     if(window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
     else if(window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return(false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if(xhr_object.readyState == 4) return(xhr_object.responseXML);
    else return(false);
}

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;
    var numDIVObjet = document.getElementById("popup_numero_retour");
        
    // Affichage de la popup de retour
    numDIVObjet.style.display = "block";

    $("#return_products_form").lionbars();

    $("#shad").addClass('actif');
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp,idOrderTransp) {

    var numRetour = "";
    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
                "ajax_num_etiquette.php?idOrder=" +
                idOrder +
                "&multiTransp=" +
                multiTransp +
                "&idOrderTransp=" +
                idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
                "ajax_num_etiquette.php?idOrder=" +
                idOrder +
                "&multiTransp=" +
                multiTransp
        );


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function() {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0  && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }

            data = $('#return_products_form').serialize();
            data += '&idOrder='+idOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;

            $.ajax({
                url : parentPath + 'ajax_create_etiquette.php',
                type : 'post',
                data : data,
                success : function (res) {
                    location.reload();
                }
            });

        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }
    }
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf)
{
    if (!show_pdf) {
        data = $('#return_products_form').serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder='+idOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }

    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false' ) {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

// Transformation XML en tableau
function traiteXmlQte(xmlDoc) {
    var options = xmlDoc.getElementsByTagName('option');
    var optionsListe = new Array();
    for (var i=0; i < options.length; ++i) {
        var optionsListe2 = new Array();

    var id 		= options.item(i).getElementsByTagName('id');
    var label 	= options.item(i).getElementsByTagName('label');
    var color 	= options.item(i).getElementsByTagName('color');
    optionsListe2.push(id.item(0).firstChild.data,label.item(0).firstChild.data,color.item(0).firstChild.data);
    optionsListe.push(optionsListe2);
    }
    return optionsListe;
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff){

    var couleurs_id = 'couleurProd';
    var tailles_id  = 'tailleProd';

    if (elementID != 'liste_qte')
    {
        couleurs_id += '_'+idProduit;
        tailles_id  += '_'+idProduit;
    }

    var listQte = $('#'+elementID);
    var alternative_listQte = $('#liste_qte_spe');

    if (listQte.length || alternative_listQte.length)
    {
        if(listQte.length)
        {
            listQte.html('<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' + parentPath + 'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>');

            var couleurs = ( ($('#'+couleurs_id).length) ? $('#'+couleurs_id).val() : 'none' );
            var tailles = ( ($('#'+tailles_id).length) ? $('#'+tailles_id).val() : 'none' );
        }
        else
        {
            alternative_listQte.html(listQte.html());

            var couleurs = 'none';
            var tailles = 'none';
        }

        var response = ajax_file(parentPath + 'ajax_liste_qte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + '&produit_lot_nb=' + $("#produit_lot_nb").val() + '&suff=' + (suff || ''));

        if (response)
        {
            if (listQte.length)
            {
                listQte.html(response);
            }
            else
            {
                alternative_listQte.html(response);
            }

            var select_stock = $('#qteProd');
            var regexp_epuise = /Stock /;
            var txt_stock = $('#qteProd>option:selected').text();

            if( select_stock.length > 0 && txt_stock.match(regexp_epuise) != null ) {

                $("#add_alert").show();
                $("#add_basket").hide();

            }
            else {

                $("#add_alert").hide();
                $("#add_basket").show();

            }
        }
    }
}

function showQteListeGamme(elementID, idProduit,parentPath){

    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);

    if (listQte || alternative_listQte)
    {
        if (listQte)
        {
            if(document.getElementById('couleurProd_'+idProduit)) var couleurs = document.getElementById('couleurProd_'+idProduit).value;
            if(document.getElementById('tailleProd_'+idProduit)) var tailles = document.getElementById('tailleProd_'+idProduit).value;
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }
        else
        {
            var couleurs = 'none';
            var tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var string_params = '';
        if( $("#produit_lot_nb_"+idProduit).val() != undefined )
            string_params += '&produit_lot_nb=' + $("#produit_lot_nb_"+idProduit).val();

        if( $("#qte_default_"+idProduit).val() != undefined )
            string_params += '&qte_default=' + $("#qte_default_"+idProduit).val();

        var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + string_params);

        if (response)
        {
            if(listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;

        }
    }
}

function showQteListeAssoc(elementID, idProduit, parentPath) {

    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);
    listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
    var couleurs = document.getElementById('couleurProdAssoc_'+idProduit);
    var tailles = document.getElementById('tailleProdAssoc_'+idProduit);
    if (listQte || alternative_listQte) {

        if(listQte){
            var couleurs = document.getElementById('couleurProd_'+idProduit).value;
            var tailles = document.getElementById('tailleProd_'+idProduit).value;
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }
        else{
            var couleurs = 'none';
            var tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }

            var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs.value + '&idtaille=' + tailles.value);
            if (response) {
                // On ex�cute deux fois car replace ne remplace qu'une seule fois alors que nous avons deux occurences
                response = response.replace( 'qteProd_', 'qteProdAssoc_' );
                response = response.replace( 'qteProd_', 'qteProdAssoc_' );
                if(listQte) listQte.innerHTML = response;
                else alternative_listQte.innerHTML = response;

            }
    }
}

// Affiche la liste des auantites d'un produit (HTML Tag select)
function	showQteListePanier(elementID, idProduit, i, paniervirtuelId) {

    var listQtePanier = document.getElementById(elementID);

    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    if (listQtePanier) {
            var response = ajax_file('ajax/ajax_liste_qte_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&i=' + i + '&panierId=' + paniervirtuelId );
            if (response)
                listQtePanier.innerHTML = response;
    }
}

function updatePanier(idProduit, i, paniervirtuelId){
    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);
    var qte = document.getElementById('qteProd'+i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;


    ajax_file('ajax/ajax_upd_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte.value + '&panierId=' + paniervirtuelId);
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {

    if (i != '') {

        i = '_' + i;

        prixU = $('#prixU' + i).val();
    }

    var couleurs = $('#couleurProd' + i);
    var tailles = $('#tailleProd' + i);
    var res = '';
    var html_err = '';
    var flagError = false;

    if ($('#lightbox_achat_express').length > 0) {

        from_range = true;
    } else {

        from_range = false;
    }

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if(tailles.length && tailles.val() == '') {

        $('#sizeerror').slideDown();

        return false;
    } else if (couleurs && couleurs.val() == '') {

        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {

        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError) {

        alert(res);

        return false;
    } else {

        var qte = 0;

        if ($('#qteProd' + i)) {

            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none')
            idCouleur = couleurs.val();

        if (tailles && tailles.val() != 'none')
            idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            array_response = response.split("//");

            var designation = array_response[0];

            if ( titreObjet.match('^[0-9]') ) {

                if ( designation == 1 ) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';

                }

            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {

                if (document.getElementById(idElTaille).selectedIndex) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;

                } else if (lastTaille != '') {

                    designation += ' - Taille ' + lastTaille;

                }

            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {

                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {

                    designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                } else if (lastCouleur != '') {

                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == 'false') {
                $('#size_' + idTaille).addClass('disabled');
                alert(translate('product_no_stock_2'));
                return false;
            }
        }

        return array_response;
    }
}

// Affiche la liste des questions appropri�es au service demand�
function show_question(elementID, fieldID, idService, parentPath) {

    var list_question = document.getElementById(elementID),
        field = document.getElementById(fieldID);

    clearTimeout(time_out);

    if (list_question) {

        $.ajax({
            url : path_relative_root + 'ajax_list_question.php',
            type : 'post',
            data : { idService : idService },
            success : function (res) {

                list_question.innerHTML = res;
                field.innerHTML = '';
            }
        });
    }
}

// Affiche les champs appropri�es au service et � la question demand�
function show_fields(elementID, idService, idQuestion, parentPath) {

    var list_field = document.getElementById(elementID);

    clearTimeout(time_out);

    if (list_field) {

        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=' + idService + '&idQuestion=' + idQuestion);

        $('.contact-beware a').attr('href',parentPath + create_link('connexion_login') + '?from=contact&id_service=' + idService + '&id_question=' + idQuestion);

        if (response) {

            list_field.innerHTML = response;

            $( "#produit_ref_input" ).bind( "keyup", function() {
                // Recuperation de tous les produit
                getAllProduct('produit_ref');
            });

            $( "#produit_nom_input" ).bind( "keyup", function() {
                // Recuperation de tous les produit
                getAllProduct('produit_nom');
            });

            tabError = [];

            tabError[tabError.length] = ['alpha', 'contactNom', 'contact_field_nom'];
            tabError[tabError.length] = ['alpha', 'contactPrenom', 'contact_field_prenom'];
            tabError[tabError.length] = ['mail', 'contactMail', 'contact_field_mail'];

            if (idService == 7) {

                tabError[tabError.length] = ['alphanum','contactAdresse','contact_field_adresse'];
                tabError[tabError.length] = ['alphanum','contactCP','contact_field_cp'];
                tabError[tabError.length] = ['alphanum','contactVille','contact_field_ville'];
                tabError[tabError.length] = ['alphanum','contactPays','contact_field_pays'];
            } else {

                if (idService == 2 && idQuestion == 5) {

                    tabError[tabError.length] = ['alphanum','contactRefProduit','contact_field_ref_produit'];
                }

                if (idService == 14) {

                    if (idQuestion == 3) {

                        tabError[tabError.length] = ['alphanumadresse', 'contactAdresse', 'contact_field_adresse'];
                        tabError[tabError.length] = ['phone', 'contactNumPortable', 'contact_field_num_portable'];
                        tabError[tabError.length] = ['alphanum', 'contactCP', 'contact_field_cp'];
                        tabError[tabError.length] = ['alphanumadresse', 'contactVille', 'contact_field_ville'];
                        tabError[tabError.length] = ['select_date', 'garantieDateAchat', 'date_achat'];
                        tabError[tabError.length] = ['prix', 'contactPrix', 'prix'];
                        tabError[tabError.length] = ['alphanum', 'id_produit_ref', 'date_achat'];

                        // Date Achat validation
                        $('#btn_contact').click(function (e) {

                            e.preventDefault();

                            if ((document.getElementById('garantieDateAchatM').value === '') || (document.getElementById('garantieDateAchatA').value === '') || (document.getElementById('garantieDateAchatJ').value === '')) {
                                $('#garantieDateAchatLabel').addClass('labelErr');
                            } else {
                                $('#garantieDateAchatLabel').removeClass('labelErr');
                                $('#garantieDateAchatLabel').siblings('.img_error').remove();
                            }

                            if($('#id_produit_ref').val() == ''){
                                $('#produit_ref_input').attr('class', 'inputErr');
                                $('#produit_nom_input').attr('class', 'inputErr');
                                $('#id_produit_refLabel').attr('class', 'labelErr');
                                $('#produit_nom_inputLabel').attr('class', 'labelErr');



                                $('#id_produit_ref').after('<img style="margin: 2px 0 0 10px" src="' + path_relative_root + 'img/picto_panneau.png" alt="" class="img_error">');
                                $('#produit_nom_input').after('<img style="margin: 2px 0 0 10px" src="' + path_relative_root + 'img/picto_panneau.png" alt="" class="img_error">');

                            }
                            else {
                                $('#produit_ref_input').attr('class', 'inputForm');
                                $('#produit_nom_input').attr('class', 'inputForm');
                                $('#id_produit_refLabel').attr('class', '');
                                $('#produit_nom_inputLabel').attr('class', '');
                            }

                            if($('#contactMagasin').val() == ''){
                                $('#contactMagasin').after('<img style="margin: 2px 0 0 10px" src="' + path_relative_root + 'img/picto_panneau.png" alt="" class="img_error">');
                                $('#contactMagasinLabel').addClass('labelErr');
                            }
                        });
                    } else if (idQuestion == 2) {

                        var content = ajax_file('ajax_field_active_garantie.php' );

                        if (content) {

                            $('#select_garantieNomProduit').append( content);
                            $('#field_garantieNomProduit').removeClass('cache');
                            $('#field_error_garantieProduit').addClass('cache');
                            $('#field_photo').addClass('cache');
                            $('#field_error_garantieProduit td').empty();
                        } else {

                            $('#field_garantieNomProduit').addClass('cache');
                        }
                    }
                }
            }

            if (!(idService == 3 && idQuestion == 2) && idService != 7) {

                tabError[tabError.length] = ['no_empty', 'contactMessage', 'contact_field_message'];
            }
        } else {

            list_field.innerHTML = '';
        }
    }
}

function show_field_form(parentPath){
    var list_field = document.getElementById('field');
    clearTimeout(time_out);
    if (list_field) {
        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=7&idQuestion=1');
        if (response) {
            list_field.innerHTML = response;

            tabError = new Array();
            tabError[0] = new Array('alpha','contactNom','contact_field_nom');
            tabError[1] = new Array('alpha','contactPrenom','contact_field_prenom');
            tabError[2] = new Array('mail','contactMail','contact_field_mail');
            tabError[3] = new Array('alphanum','contactAdresse','contact_field_adresse');
            tabError[4] = new Array('alphanum','contactCP','contact_field_cp');
            tabError[5] = new Array('alphanum','contactVille','contact_field_ville');
            tabError[6] = new Array('alphanum','contactPays','contact_field_pays');
        }
        else
            list_field.innerHTML = '';
    }
}

// Affiche les produits de la commande appropri�es au service litige Contact
function show_product_commande(idCommande, parentPath){

    var list_field =  document.getElementById('field_produit_commande');
    if (idCommande) {
        var response = ajax_file(parentPath + 'ajax_field_product_commande.php?idCommande=' + idCommande);
        if (response){
            list_field.innerHTML = response;
            $('#produitCommande').removeClass('cache');
        }
    }else{
        list_field.innerHTML = "";
        $('#produitCommande').addClass('cache');
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_send').addClass('cache');
    }
}

function paginationGoToPage(page) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc);
    }
}

function paginate(page) {

    var form = document.getElementById('filter_sticky'),
        total_element = form.getValue('totalElems', parseFloat),
        nb_prod = form.getValue('nb_prod', parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper.pager_wrapper_rayon'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            pagination.each(function () {

                if (nb_prod != 1000 && nb_prod < total_element) {
                    this.innerHTML = res + '<div class="see_all_product" onclick="seeXProduct(1000)">' + translate('see_all') + '</div>';
                } else if (total_element > nb_prod_default) {
                    this.innerHTML = '<div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ')">' + translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}

function seeXProduct(nb_product) {

    // On definie le nombre de produit a charger à 1000
    $('#nb_prod').val(nb_product);

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(0);
    } else {

        generateNewBlocProd(0);
    }

}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String			type       [description]
 * @param  Number			idObj      [description]
 * @param  Number			page       [description]
 * @param  Number			nb_product [description]
 * @param  HTMLElement		btn		   le bouton sur lequel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function onColorCheckChange(evt) {

    if (this.checked && !evt.ctrlKey) {

        $(this.closest(".selected_options"))
            .find("input[type=checkbox]:checked")
            .not(this)
            .prop("checked", false);
        //
    }
}

function generateNewBlocProd(page, type, idObj, nb_product, btn) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {},
        is_from_page = false,
        key;

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky');


    if (page === 'all') {
        /* LEGACY */
            var hash;

            if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0])) {

                var marginTop = $('.banner_wrapper').height() + 20,
                    $elem = hash ? $('#' + hash.replace('=', '')) : $list_item,
                    offset = $elem.offset(),
                    top = offset && offset.top - marginTop;

                window.setTimeout(function () {

                    $('#contents_wrapper').animate({ scrollTop : top }, 0.8);
                }, 0);
            }

        /* END LEGACY */
    } else {

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url : path_relative_root + 'ajax_reload_products.php',
            type : 'get',
            data : output,
            success : function (res) {

                var new_elm = document.createElement('div'),
                    old_elm = document.getElementById('scroll_items'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='en-gb']").attr("href", JSON.parse(res[8])[2].en);
                $("link[rel^='alternate'][hreflang^='x-default']").attr("href", JSON.parse(res[8])[1].en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');
                
                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {

                    new_elm.id = 'list_item';
                    // new_elm.className = 'items_wrapper' + (readCookie("nb_produits") == 4 ? ' fourRows' : '');
                    new_elm.className = 'items_wrapper byThree';

                    new_elm.innerHTML = content;

                    if (!isNaN(page)) {

                        new_elm.setAttribute('data-p', (page * nb_product));
                    }

                    old_elm.innerHTML = '';

                    document.getElementById('totalElems').value = itm_length;
                    $('.items_found .nb').html(itm_length);

                    paginate(page + 1);

                    old_elm.appendChild(new_elm);

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    /* LEGACY */
                        var js_compteur = $('.vf_countdown').length;
                        var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                        if( js_compteur != null ){
                            try{
                                var cnt_compteur = js_compteur.length;

                                for( var i = 0; i < cnt_compteur; i++ ){
                                    eval( js_compteur[i].replace( /(vf_compteur_begin|vf_compteur_end)/g, "" ) );
                                }
                            }
                            catch( e ){}
                        }
                        if( js_encart != null ){
                            try{
                                js_encart = js_encart[0].replace( /(\/\/vf_begin|\/\/vf_end)/g, "" );
                                eval( js_encart );
                            }
                            catch( e ){}
                        }

                    /* END LEGACY */

                } else {
                    content = '<div class="no_results_wrapper">' +
                                    '<div class="search_details_wrapper">' +
                                        '<div class="no_result_block">' +
                                                translate('no_result_txt') +
                                        '</div>' +
                                    '</div>' +
                                '</div>';
                    old_elm.innerHTML = content;
                    $('.items_found .nb').html(0);
                    old_elm.appendChild(new_elm);
                }

                switchVisu();
            },
            complete : function () {
                if ($("#nb_prod").val() != 1000) {
                    setTimeout(function () {
                        $('html, body').animate({ scrollTop : 0 }, 200);
                    }, 250);
                }
            }
        });
    }
}

function updateFilter(tab_response, page, parentPath, type, idObj, no_price_range_update) {
    if( tab_response[0] != '' ) {
        var bloc_photo = tab_response[0];
        document.getElementById('list_item').innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function(){
            $(this).hover(function(){
                $(this).children('.visu_product_slider').children('.visu_product_slider_hover').show();
            }, function(){
                $(this).children('.visu_product_slider').children('.visu_product_slider_hover').hide();
            });
        });

        var nav = tab_response[2];
        var nav_bas = tab_response[3];

        var nb_prod = tab_response[4];

        if(document.getElementById('nb_prod_numeric')) document.getElementById('nb_prod_numeric').innerHTML = nb_prod;
        if(document.getElementById('label_nb_prod_numeric')) {
            if( nb_prod == '' )
                document.getElementById('label_nb_prod_numeric').style.display = 'none';
            else
                document.getElementById('label_nb_prod_numeric').style.display = 'block';
        }

        if (typeof(page) == 'undefined') {

            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update)
            {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);
                $("#slider-range").slider( "option" , { min:parseInt(prix_min),max:parseInt(prix_max),values:[parseInt(prix_min),parseInt(prix_max)]} );
                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css('left','0%');
                $(".ui-slider-range").next().css('left','0%');
                $(".ui-slider-range").next().next().css('left','100%');
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split('~');
            var cnt_color = tab_color_tmp.length;

            if( tab_color_tmp[0] != '' ) {

                txt_color = ajax_file(parentPath + 'ajax_sprintf.php?arg1=recherche_couleurs');
                var bloc_color = '<div class="select_recherche">'+txt_color+'</div><div class="selected_options select_colors"></div>';

                var str_color = '';

                for( var j=0 ; j<cnt_color ; j++ ) {

                    var detail_color = tab_color_tmp[j].split('|');
                    var class_color = 'btn_select_off';

                    if( tab_color.length > 0 ) {
                        if( in_array_js(detail_color[0],tab_color) ) {
                            class_color = 'btn_select';
                        }
                    }
                    str_color += '<div id="couleur_' + detail_color[0] + '" class="left_menu_obj '+class_color+' couleur_' + detail_color[0] + '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' + detail_color[1] + /* ' (' + detail_color[2] + ')*/'</div></div>';
                }

                $("#bloc_couleur").show().find(".selected_options").html(str_color);
            } else {
                $("#bloc_couleur").hide().find(".selected_options").html('');
            }
            // size
            var size = tab_response[8];

            var tab_size_tmp = size.split('~');
            var cnt_size = tab_size_tmp.length;

            if( tab_size_tmp[0] != '' ) {

                txt_size = ajax_file(parentPath + 'ajax_sprintf.php?arg1=recherche_taille');

                var bloc_size = '<div class="select_recherche">'+txt_size+'</div><div class="selected_options"></div>';

                var str_size = '';

                for( var j=0 ; j<cnt_size ; j++ ) {

                    var detail_size = tab_size_tmp[j].split('|');
                    var class_size = 'btn_select_off';

                    if( tab_taille.length > 0 ) {
                        if( in_array_js(detail_size[0],tab_taille) )
                            class_size = 'btn_select';
                    }

                    str_size += '<div id="taille_' + detail_size[0] + '" class="left_menu_obj '+class_size+'"><div class="f_left"></div><div class="f_left txt_menu">' + detail_size[1] + '</div></div>';

                }

                $("#bloc_taille").show().find(".selected_options").html(str_size);
                var windowW = $(window).height();
                if(windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }

            } else {
                $("#bloc_taille").hide().find(".selected_options").html('');
            }
        }
    }
    else {
        $('#list_item').html(ajax_file(parentPath + 'ajax_sprintf.php?arg1=no_response_search'));
        $('#pagination_haut').html('');
        $('#pagination_bas').html('');
    }
    $(".left_menu_obj").on('click touchend', filterBoxesToggle);
    var windowW = $(window).height();
    if(windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }
    $('#page').val(0);
}


/*  On va regarde tout les bloc et on envoi l'ajax
    Pour les blocs de cases � cocher
    Pour le slider des prix
    Pour le bouton qui supprime un element */
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        output = {},
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.value !== '') {

                if (output[elm.name] === undefined) {

                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {

            if (elm.value !== '') {

                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {

        if (output.hasOwnProperty(key)) {

            if (output[key].constructor === Array) {

                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {

        form.nb_prod.value = nb_product;
    } else {

        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {

        form.page.value = page * nb_product;

        is_from_page = true;
    }

    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Si le prix existe
    if ($('#price_min').length > 0) {
        // Récupération du prix
        output.prix = form.getValue('price_min', parseFloat) + '~' + form.getValue('price_max', parseFloat);
    }

    if (bloc_search_xhr) {

        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url : path_relative_root + 'ajax_reload_search.php',
        type : 'get',
        data : output,
        success : function (res) {

            var new_elm = document.createElement('div'),
                old_elm = document.getElementById('scroll_items'),
                content, itm_length;

            if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split('~');

            itm_length = parseFloat(res[1]);

            if (content !== '') {

                new_elm.id = 'list_item';
                // new_elm.className = 'items_wrapper' + (readCookie("nb_produits") == 4 ? ' fourRows' : '');
                new_elm.className = 'items_wrapper byThree';
                new_elm.innerHTML = content;

                if (!isNaN(page)) {

                    new_elm.setAttribute('data-p', (page * nb_product));
                }

                old_elm.innerHTML = '';

                document.getElementById('nb_articles').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                old_elm.appendChild(new_elm);

                if (window.lazyload !== undefined) {

                    lazyload.init();
                }

                /* LEGACY */

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if( js_compteur != null ){
                        try{
                            var cnt_compteur = js_compteur.length;

                            for( var i = 0; i < cnt_compteur; i++ ){
                                eval( js_compteur[i].replace( /(vf_compteur_begin|vf_compteur_end)/g, "" ) );
                            }
                        }
                        catch( e ){}
                    }
                    if( js_encart != null ){
                        try{
                            js_encart = js_encart[0].replace( /(\/\/vf_begin|\/\/vf_end)/g, "" );
                            eval( js_encart );
                        }
                        catch( e ){}
                    }

                /* END LEGACY */
            }
        },
        complete : function () {

            setTimeout(function () {
                $('html, body').animate({ scrollTop : $('#content_obj').position().top - $('#site_head_wrap').height() }, 200);
            }, 250);
        }
    });
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while( document.getElementById('onglet_'+i+'_mid') ) {
        if( type == i ) {

            $("#onglet_"+i+"_left").attr('class','left-on');
            $("#onglet_"+i+"_mid").attr('class','mid-on');
            $("#onglet_"+i+"_right").attr('class','right-on');

        }
        else {

            $("#onglet_"+i+"_left").attr('class','left-off');
            $("#onglet_"+i+"_mid").attr('class','mid-off');
            $("#onglet_"+i+"_right").attr('class','right-off');

        }
        i++;
    }

    var container = document.getElementById(elementID);

    // clearTimeout(time_out);
    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        }
        else{
            container.innerHTML = '';
            return false;
        }
    }
    else
        return false;
}

/* Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php) */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr('id'),
        mId = id.substring(id.indexOf('_') + 1),
        messageContainer = $('.message_account_container');

    if (messageContainer.hasClass('appear')) {
        messageContainer.removeClass('appear');
    } else {
        messageContainer.addClass('appear');
    }

    $('.btn_ouvrir_message').not(elem).text(msg_open);
    var bloc_message = $('#msg_' + mId);
    var message = $('#msg_' + mId + ' .message_detail');

    if (!(elem).hasClass('actif')) {
        $('.btn_ouvrir_message').not(elem).removeClass('actif');
        if (message.html() === '') {
            $.ajax({
                type: 'post',
                data: { mId: mId, type: 1 },
                url: path_relative_root + create_link('message_detail'),
                success: function (response) {
                    message.html(response);
                    bloc_message.slideDown();
                    bloc_message.addClass('actif');
                }
            });
        } else {
            bloc_message.slideDown();
            bloc_message.addClass('actif');
        }
        (elem).addClass('actif');
        (elem).text(msg_close);
    } else {
        bloc_message.slideUp();
        bloc_message.removeClass('actif');
        (elem).removeClass('actif');
        (elem).text(msg_open);
    }
}

function show_message(mId, hide) {

    if (hide == 0) {
        var ajax_content = ajax_file('ajax_show_messages.php?mId=' + mId);
        if (ajax_content) {

            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = '';
            document.getElementById("message_" + mId).style.cursor = 'default';

        }
    } else {
        var ajax_content = ajax_file('ajax_show_messages.php?mId=' + mId + '&hide=1');
        if (ajax_content) {

            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = "show_message(" + mId + ",0);";

            document.getElementById("message_" + mId).style.cursor = 'pointer';

        }
    }
}

var timer;
// --------------------------------------------------------------------------------
function newNewsletterFromCmpid() {

    var flag = verif_mail( 'email_inscription_home','login_email' );

    if( flag ) {

        flag = ajax_file(path_relative_root+'ajax_email_newsletter_exist.php?mail='+ document.getElementById('email_inscription_home').value);

        var elementID = 'email_inscription_home';

        var objElement = document.getElementById(elementID);
        var objLabel = document.getElementById(elementID + 'Label');
        var erreurText = document.getElementById('erreur_msg');

        if( flag === '1' ) {
            flag = false;
        }
        else {
            flag = true;
        }

        if( !flag ) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path+'ajax_sprintf.php?arg1=login_email') + ' :</p>' + 'Cet email est déjà inscrit à la newsletter';
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';

        }
        else {

            objElement.className = 'inputForm';
            objElement.style.border="1px solid #CCCCCC";
            objLabel.className = 'labelForm';

        }
    }

    if( flag ) {

        clearTimeout(timer);

        document.getElementById("bloc_erreur").style.display = 'none';
        document.getElementById("img_error_popup").style.display = 'none';

    }else{

        var val = document.getElementById('erreur_msg').innerHTML;
        var elem_img = document.getElementById("img_error_popup");
        $("#img_error_popup").css('display','inline-block');

        elem_img.setAttribute('onmouseover',function(){OverPopup(this,val,'visible','cmpid_adwords');});
        elem_img.onmouseover=function(){
            OverPopup(this,val,'visible','cmpid_adwords');
        };
        elem_img.setAttribute('onmouseout',function(){OverPopup(this,val,'none','cmpid_adwords');});
        elem_img.onmouseout=function(){OverPopup(this,val,'none','cmpid_adwords');};

        elem = document.getElementById('email_inscription_home');

        elem.style.border="1px solid red";
        pos = getPos(elem_img);

        document.getElementById('bloc_erreur').style.position = 'absolute';

        var nav = navigator.appVersion;

        if(!(/*@cc_on!@*/false || !!document.documentMode)) {
            $("#bloc_erreur").fadeIn(400);
        }
        else {
            document.getElementById("bloc_erreur").style.display = 'block';
        }

        var clone = $('#bloc_erreur').clone(),
        c_pos = {
            top : $('#img_error_popup').offset().top - $('#img_error_popup').offsetParent().offset().top - $('#bloc_erreur').height() + 4,
            left : $('#img_error_popup').offset().left - $('#img_error_popup').offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($('#img_error_popup').width() * 2) + 6
        };

        $('#bloc_erreur').remove();
        $('#img_error_popup').after(clone);

        $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
            'float' : 'right',
            'margin' : '0 7px'
        });

        $('#bloc_erreur').css({
            'position' : 'absolute',
            'top' : c_pos.top,
            'left' : c_pos.left+271,
            'z-index' : '99999',
            'opacity' : '1'
        });

        window.clearTimeout(timer);

        timer = setTimeout("closeInfobulle('bloc_erreur')",5000);

    }

    return flag;

}

function checkAndAddMail() {
    if( newNewsletterFromCmpid() ) {

        $("#btn_conf_cmpid").css('display','none');
        $("#loader_popup").css('display','block');

        response = ajax_file(path_relative_root+'ajax_add_email_newsletter.php?mail='+ document.getElementById('email_inscription_home').value+'&from=lightbox');

        if( response == 1 ) {

            $("#formu_inscription_news").fadeOut(750,function() {
                $("#conf_inscription_news").fadeIn(750);
            });

            document.getElementById('shadCmpid').setAttribute('onclick',function(){ closeMultiShad('popup_inscription_home'); });
            document.getElementById('shadCmpid').onclick=function(){ closeMultiShad('popup_inscription_home');}

        }

    }

}


function ajaxDeconnect(path_relative_root){

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;

}

/* wishlist */
function addToWishlistShoppingcart()
{
    $.post(path_relative_root + "ajax_cart_to_wishlist.php", function(resp) {
        $(".btn_top_wishlist .gris").html('(x' + resp.result.wishlist_size + ')');
        $("#addToWishlistShoppingcart").removeClass("addToWishlistShoppingcart").addClass("existsToWishlistShoppingcart");
        $("#addToWishlistShoppingcart").html(resp.result.button_label);
        $("#addToWishlistShoppingcart").attr("onclick","").attr("href", path_relative_root + create_link('client_index') +'#wl');
    });
}

function deletePromoCode() {
    $.ajax({
        type: 'get',
        url: path_relative_root+create_link('delete_promo_code'),
        success: function( response ){
            location.href = path_relative_root + create_link('order_kiala');
        }
    });
}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {

    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte
        },
        url: path_relative_root + 'ajax_removeBasketProd.php',
        success: function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');

                return;
            }

            $('#cart_top').html(res.datas.html);
            $('#show_top_cart').addClass('appear');
            $('#cart_list').lionbars();
            $('#cart_top').on('mouseenter touchstart', function(evt) {
                $('#cart_list').lionbars();
            });
        }
    });
}

function clearInput(ctrlId) {

    var old = document.getElementById(ctrlId);
    var newElm = document.createElement('input');
    newElm.type = "file";
    newElm.id = ctrlId;
    newElm.name = old.name;
    newElm.className = old.className;
    newElm.style.paddingLeft = '33px';
    newElm.style.paddingTop = '-2px';
    newElm.style.float = 'left';
    // Put code to copy other attributes as well
    old.parentNode.replaceChild(newElm, old);
}

function sendMessage(id) {

    // disable the default form submission

    var mId = id;
    $('.loader_reponse_contact').css("display", "block");
    $('#envoyer_rep').addClass('active');

    if( $('#msg_txt_'+id).val() != '' ) {
        var upload_fail = ajax_file( 'ajax_sprintf.php?arg1=contact_msg_pj_fail' );
        var formData = new FormData($('form#data')[0]);
        $.ajax({
            url: path_relative_root+create_link('message_detail'),
            type: 'POST',
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function( response ) {
                if (response == '1') {
                    $('#msg_conf_' + id).html(ajax_file( 'ajax_sprintf.php?arg1=contact_msg_envoyer' ));
                    $('#msg_conf_' + id).show();
                    $('#msg_conf_' + id).addClass('active');
                    $("#msg_txt_" + id).val(' ');

                    $('#envoyer_rep').addClass('active');
                    setTimeout(function() {
                        $("#btnSendResponse").removeClass("loading");
                        return false;
                    }, 400);
                }
                else if(response == '3'){
                    clearInput('filepj1');
                }
                else {
                    $('#msg_conf_' + mId).html(ajax_file( 'ajax_sprintf.php?arg1=contact_msg_fail' ));
                    $('#msg_conf_' + mId).show();
                }
            }
        });
    }
    else{

        $('#contact_msg_text_vide').css('color','red').fadeIn();
        setTimeout(function() {

            formMessageReset(id);
        }, 5000);
        setTimeout(function() {

            $("#btnSendResponse").removeClass("loading");
        }, 400);
        $('#msg_conf_'+mId).hide();
    }
}

function cancelMessage(id) {

    "use strict";

    $('#msg_txt_'+id).val('');
}

function formMessageReset(id){

    "use strict";

    $('#contact_msg_text_vide').fadeOut();
    if(! $('#msg_conf_' + id).hasClass('active') ){

        $('#envoyer_rep').removeClass('active');
    }
}

function ajax_achat_express(reference, is_alliance, wishlist, idprod, code_couleur){
    var arg = '';

    if (wishlist !== undefined) {

        arg = '&wishlist=true';
    }
    var self = $(this);

    $.ajax({
        type: 'get',
        url: path_relative_root+'ajax_achat_express.php?ref='+reference+'&couleur='+code_couleur+arg,
        success: function( response ){
            var $lighbox = $( '#lightbox_achat_express' );
            var $shad = $( '#shad' );
            if(!$lighbox.length){
                $('body').append('<div id="lightbox_achat_express"></div>');
                $lighbox = $( '#content_achat_express' );
            }
            $lighbox
                .html( '<div class="express_response"><button class="close_pop" onclick="closeMultiShad(\'lightbox_achat_express\')">'+ '<span>X</span>' +'</button>'+ response +'</div>' )
                .appendTo( 'body' )
            ;
            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', idprod, path_relative_root);

            // event sur le bouton d'ajout à la wishlist
            $("#addToWishlistButton").on("click", addToWishlist);


            // Pour les event des tooltips "Veuillez selectionner une taille"
            eventButton();
            setTimeout(function () {
                checkSubmitState();
            }, 500);
        }
    });
}

function showLightBoxSelectionOfferte() {
    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_lightbox_selection_offerte.php',
        success: function (response) {
            var $shad = $('#shad');
            if ($('#selectionOffertePopup').length == 0) {
                $("body").append(response);
            }

            var id_produit_offert_unique = $('.ligne_produit_offert').length == 1 ? $('.ligne_produit_offert').data('idproduit'):0;

            // Si le nombre de selection égale 1 on ajoute le produit directement dans le panier, sinon on affiche la LightBox avec la selection
            if (nb_selection_offert == 1 && $('.ligne_produit_offert').length == 1 && $("#qteProd_" + id_produit_offert_unique).val()>0) {
                $('.ligne_produit_offert').addClass('seleted');
                ajoutLightBoxSelectionOfferte();
            } else {
                show_popup('selectionOffertePopup');
                $(".productsWrapper").lionbars();
            }
            $shad.click(function () {
                closeMultiShad();
            });
        }
    });
}


function show_ml(elem) {
    $('#mention_legales_pop_up').css("display","block");
    show_popup(elem);
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr('onclick', '');
    $shad.animate({opacity: 0}, 350, function () {
        $shad.css({display: 'none', opacity: ''}).data('opened_lb', 0);
    });
    $modbox.css({display: 'none'});

    bodyScrollUnlock();
}

function deleteCoupon() {
    $.ajax({
        type: 'get',
        data: {
            act: 'delcp'
        },
        url: path_relative_root + create_link('order_basket')
    });
}


function temporisationLightBoxSelectionOfferte() {
    $('.dblocked').prop('disabled',true);
    $('.wrapper_txt', '.bloc_btn_select').hide();
    $('#loaderProdOffert', '.bloc_btn_select').show();
    window.setTimeout(ajoutLightBoxSelectionOfferte, 1000);
}


function ajoutLightBoxSelectionOfferte() {
    var tab_prod_offert = new Array();
    var total_prod_offert_selec = 0;
    var totalProdActiv = 0;
    // Pour chaque produit sélectionné, on va récupérer la quantité (pour savoir si onb a sélectionné le nombre maximal de produit)
    // ainsi que le produit avec sa taille et sa couleur (tab_prod_offert) pour l'ajouter comme produit selectionné offert
    // (plus bas ajax_ajout_selection_offerte.php)
    $('.ligne_produit_offert.seleted').each(function () {
        totalProdActiv++;
        var id_produit = $(this).data('idproduit');
        var taille_prod = $("#tailleProd_" + id_produit).val();
        if (typeof (taille_prod) == 'undefined')
            taille_prod = 0;
        var couleur_prod = $("#couleurProd_" + id_produit).val();
        if (typeof (couleur_prod) == 'undefined')
            couleur_prod = 0;
        var qte_prod = $("#qteProd_" + id_produit).val();
        if (qte_prod > 0) {
            tab_prod_offert.push(new Array(id_produit, taille_prod, couleur_prod, qte_prod));
            total_prod_offert_selec += parseInt(qte_prod);
        }
    });
    if (total_prod_offert_selec > 0 && total_prod_offert_selec <= nb_selection_offert_restant) {
        var cnt_prod_offert = tab_prod_offert.length;
        $.ajax({
            type: 'post',
            data: {
                tab_prod_offert: tab_prod_offert
            },
            url: path_relative_root + 'ajax_ajout_selection_offerte.php',
            success: function (response) {
                location.reload();
                $('.wrapper_txt', '.bloc_btn_select').show();
                $('#loaderProdOffert', '.bloc_btn_select').hide();
            }
        });
    } else {
        var $wp = $('.wrapper_product').css({
            position: 'relative'
        });
        var $alert = $('<div><span></span>x</div>');
        $wp.before($alert);
        $alert.addClass('alert').css({
            opacity: 0,
            display: 'block',
            position: 'absolute',
            padding: '20px 40px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            maxWidth: 500,
            zIndex: 500
        });
        $('.close', $alert).css({
            position: 'absolute',
            top: 5,
            right: 5,
            cursor: 'pointer'
        });
        if (totalProdActiv == 0) {
            if (Error1 != undefined && Error1 != '') {
                $('span', $alert).html(Error1);
            } else {
                $('span', $alert).html('Veuillez choisir au moins 1 produit avant d\'ajouter au panier');
            }
        } else {
            if (Error2 != undefined && Error2 != '') {
                $('span', $alert).html(Error2);
            } else {
                $('span', $alert).html('Veuillez choisir toutes les caractéristiques (taille ou couleur) correspondantes aux produits sélectionnés avant d\'ajouter au panier');
            }
        }
        $alert.css({
            margin: '-' + ($alert.outerHeight(true) / 2) + 'px 0 0 -' + ($alert.outerWidth(true) / 2) + 'px',
            left: '50%',
            top: '50%'
        });
        $alert.animate({
            opacity: 1
        }, 350, function () {
            $alert.css({
                opacity: ''
            });
        });
        $('body').click(function (e) {
            if ($alert) {
                $alert.css({
                    opacity: 1
                }).animate({
                    opacity: 0
                }, 350, function () {
                    $alert.remove();
                });
            }
        });
        $('.wrapper_txt', '.bloc_btn_select').show();
        $('#loaderProdOffert', '.bloc_btn_select').hide();
    }
}

var array_free_products_lightbox = [];
var nbrGiftProdOrigin = -1

function updateTopCountFreeLightbox(obj, product_id) {
    if (array_free_products_lightbox[product_id] == undefined) {
        array_free_products_lightbox[product_id] = 0;
    }

    var current_val = obj.value;
    var dif = array_free_products_lightbox[product_id] - current_val;

    if (typeof nbrGiftProd == 'number') {
        nbrGiftProd += dif;
        $('#nb_prod_selection_offerte_lightbox').html(nbrGiftProd);
    }

    array_free_products_lightbox[product_id] = current_val;

    if (typeof nbrGiftProd == 'number' && nbrGiftProd == 0) {
        $('.ligne_produit_offert').each(function () {
            if (!$(this).hasClass('seleted')) {
                $(this).addClass('disable');
                $(this).stop(true).css({
                    display: 'block',
                    opacity: 1
                }).animate({
                    opacity: 0.3
                }, 300);
            }
        });
    } else {
        $('.ligne_produit_offert').each(function () {
            if ($(this).hasClass('disable')) {
                $(this).removeClass('disable');
                $(this).stop(true).css({
                    display: 'block'
                }).animate({
                    opacity: 1
                }, 300, function () {
                    $(this).css({
                        opacity: ''
                    });
                })
            } else {
                $(this).removeClass('disable');
            }
        });
    }
}

function activeFormProduitOffert(obj, id, event) {
    var parent = $(obj);
    obj = $('.btn_radio', parent);
    var container = $(obj).parents('.content_product');
    var clickable;
    if ($(event.target).closest('.third_bloc_produit_offert').length == 0) {
        clickable = true;
    } else {
        clickable = false;
    }

    if (nbrGiftProdOrigin == -1) {
        nbrGiftProdOrigin = nbrGiftProd;
    }

    if (clickable == true) {
        if (!parent.hasClass('disable')) {
            if (obj.hasClass('actif')) {
                stop_prod_offert = false;
                $('#selectionOffertePopup .ligne_produit_offert .third_bloc_produit_offert').each(function () {
                    $(this).parent().removeClass('inactif');
                });
                obj.removeClass('actif');
                parent.removeClass('seleted');
                $("#form_produit_offert_" + id).stop(true, true).css({
                    display: 'block',
                    opacity: 1
                }).animate({
                    opacity: 0
                }, function () {
                    $("#form_produit_offert_" + id).css({
                        opacity: '',
                        display: 'none'
                    });
                    $("#liste_qte_" + id).html('');
                });
            } else {
                if (!stop_prod_offert) {
                    showQteListeGamme('liste_qte_' + id, id, path_relative_root);
                    var options = $('#liste_qte_' + id).find('select')[0].options;
                    var arrayToDelete = [];
                    $.each(options, function () {
                        if (parseInt($(this).val()) > parseInt(nb_selection_offert_restant)) {
                            arrayToDelete[arrayToDelete.length] = $(this);
                        }
                    });
                    $.each(arrayToDelete, function () {
                        $(this).remove();
                    });
                    $("#form_produit_offert_" + id).stop(true, true).css({
                        display: 'block',
                        opacity: 0
                    }).animate({
                        opacity: 1
                    }, function () {
                        $("#form_produit_offert_" + id).css({
                            opacity: ''
                        });
                        $('#qteProd_' + id).change(function(){ updateTopCountFreeLightbox(this, id); });
                    });
                    obj.addClass('actif');
                    parent.addClass('seleted');
                    $('.selectStyled').each(function () {
                        $(this).selectStyled();
                    });
                    if (typeof nbrGiftProd == 'number') {
                        if (nbrGiftProdOrigin == 1) {
                            $('#form_produit_offert_' + id).find('.product_offer_quantity').css('display', 'none');
                            $('#qteProd_' + id).val(1);
                            updateTopCountFreeLightbox(document.getElementById('qteProd_' + id), id);
                        }
                    }
                }
            }
        }
    }
}

function switchVisu() {
    var src_format = $('#list_item').length && $('#list_item').hasClass('bydefault')? 'large':'medium';
    var dest_format = $('#list_item').length && $('#list_item').hasClass('bydefault')? 'medium':'large';

    $('.itm_vis').each(function (){
        if(typeof($(this).attr('data-src'))!='undefined') {
            $(this).attr('data-src',$(this).attr('data-src').replace(dest_format,src_format));
        }
        if(typeof($(this).attr('src'))!='undefined') {
            $(this).attr('src',$(this).attr('src').replace(dest_format,src_format));
        }
    });
}
switchVisu();

function viewByThree(){
    if($('#list_item').length && $('#list_item').hasClass('bydefault')){
        $('#list_item').removeClass('bydefault');
        $('#list_item').addClass('byThree');
        $('#viewChanger .byThree').addClass('actif');
        $('#viewChanger .default').removeClass('actif');
        switchVisu();
    }
}

function viewDefault(){
    if($('#list_item').length && $('#list_item').hasClass('byThree')){
        $('#list_item').removeClass('byThree');
        $('#list_item').addClass('bydefault');
        $('#viewChanger .byThree').removeClass('actif');
        $('#viewChanger .default').addClass('actif');
        switchVisu();
    }
}

/* Permet de calculer les mensualités d'un paiement Cofidis 3x */
function calculCofidis3Cb()
{
    var amount = $('#montantCalcul').val();
    if(amount > 0) {
        $.ajax({
            url : 'https://www.simulateurcofidis.com/3cb/index.php',
            type : 'get',
            data : {'montant': amount, 'key': 'RlX3lf1f98Cal6r0v1ul7Phq7YfJSdnybnvuXuL6T9RaVGhRyr', 'defaut': 0, 'retour': 'flux'},
            success : function (response) {
                var resJson = JSON.parse(response);
                $('#tab_mensualites .tab_amount span').each(function(k, v) {
                    v.innerHTML = renderAmount(resJson.derniere_mensulatie);
                });
                $('#tab_mensualites .montant_du .tab_amount span').html(renderAmount(resJson.montant_du));
            }
        });
    }
}

/**
 * Permet de retourner le montant formaté ou non
 * 
 * @param {string} amount
 * @returns {string|int}
 */
function renderAmount(amount)
{
    return amount.toString().includes(',') || amount.toString().includes('.')
    ? Number.parseFloat(amount).toFixed(2).toString().replace('.', ',')
    : amount;
}