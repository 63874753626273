$(function () {

    "use strict";

    /* HEADER OPACITY ON SCROLL (ESPECIALLY FOR THE HOMEPAGE) */
    var header = document.getElementById("site_head_wrap");
    var shad = $(".shad");
    var scrollTop = $(document).scrollTop();

    function onWinScroll() {
        scrollTop = $(document).scrollTop();
        if (header.classList.contains("sticky") && !$(".wrap_search_field").hasClass('searching')) {
            if (scrollTop === 0) {
                header.classList.remove("sticky");
            }
        } else {
            if (scrollTop !== 0) {
                header.classList.add("sticky");
            }
        }
    }

    $(window).on("scroll", onWinScroll);

    onWinScroll();

    /* MAIN MENU TOGGLE */
    function toggleMenu() {
        $(".innerMenu").on("click touch", function () {
            $("#mainMenu").toggleClass("opened");
            $(".logo").toggleClass("hide");
            shad.toggleClass("inMenu");
            shad.toggleClass("actif");
        });

        shad.on("click", function () {
            $("#mainMenu").removeClass("opened");
            shad.removeClass("inMenu");
            shad.removeClass("actif");
        });
    }

    toggleMenu();
    /* INNER MENU TOOGLE */
    $(".desktop .submenu").on("mouseover", function () {
        var targeted = $(this).children(".ss_menu_categ_wrapper");
        header.classList.add("sticky");
        targeted.addClass("opened");
        $(this).children('.cat_name').addClass('actif');
    });

    $(".desktop .submenu").on("mouseout", function () {
        var targeted = $(this).children(".ss_menu_categ_wrapper");
        if(scrollTop === 0) {
            header.classList.remove("sticky");
        }
        targeted.removeClass("opened");
        $(this).children('.cat_name').removeClass('actif');
    })

    $(".tablet .submenu").on("click touch", function () {
        var targeted = $(this).children(".ss_menu_categ_wrapper");
        targeted.toggleClass("opened");
        $(this).children('.cat_name').removeClass('actif');
    });




    var gondoleHome = $(".tete-gondole");

    if (gondoleHome.length) {

        gondoleHome = new Swiper('.tete-gondole', {

            slidesPerView: 4,
            spaceBetween: 16,
            navigation: {

                prevEl: '.teteGondolePrev',
                nextEl: '.teteGondoleNext'
            }
        });
    }

    $(".ss_cat_name").on("click touch", function (event) {

        var targeted = $(this).next(".subCatWrapper");
        var targetLocation = $(this).attr("href");
        var targetedChildren = $(targeted).find('li');

        if (targeted.length) {
            event.preventDefault();

            if (targeted.is(":visible")) {

                if (targetLocation.length > 0) {
                    document.location.assign(targetLocation);

                } else {
                    $(".subCatWrapper.opened").slideUp();
                    targeted.removeClass("opened");
                }
            } else if (targetLocation.length > 0 && targeted.is(':empty')) {
                document.location.assign(targetLocation);

            } else {

                $(".subCatWrapper.opened").slideUp();
                targeted.slideDown();
                targeted.addClass("opened");

                if (targetedChildren.length >= 7) {
                    new SimpleBar(document.querySelector('.navWrapper'));
                }
            }
        }
    });

    /* TOP SEARCH ANIMATION */
    var trigger_recherche = $("#trigger_recherche");
    var wrap_search_field = $(".wrap_search_field");

    if (trigger_recherche.length > 0) {
        trigger_recherche.on("click touch", function (evt) {
            header.classList.toggle("sticky");
            wrap_search_field.toggleClass("searching");

            // Handle header stickyness and shad when search icon is clicked
            if (wrap_search_field.hasClass('searching')) {
                shad.addClass("actif search");
                header.classList.add("sticky");
            } else {
                shad.removeClass("actif search");
                header.classList.remove("sticky");
            }
        });
    }

    // Handle closing search when shad is clicked
    shad.on('click', function() {
        setTimeout(() => {
            closeSearchBar();
        }, 200);
    })

    /* TOP ACCOUNT ROLLOVER */
    var topAccount = $(".topAccount.is_logged");
    var topAccountRollover = $(".topAccountRollover");
    var sahd = $(".shad");


    function addAccountMenu() {
        document.querySelector('.topAccount.is_logged').classList.add('hovering');
        document.querySelector('.topAccountRollover').classList.add('opened');
        document.querySelector(".shad").classList.add("actif");
        document.querySelector(".shad").classList.add("inMenu");
    }
    function removeAccountMenu() {
        document.querySelector('.topAccount.is_logged').classList.remove('hovering');
        document.querySelector('.topAccountRollover').classList.remove('opened');
        document.querySelector(".shad").classList.remove("actif");
        document.querySelector(".shad").classList.remove("inMenu");
    }

    //action pour ouvrir le menu compte
    $(".topAccount.is_logged").on("mouseenter", addAccountMenu);
    $(".topAccount.is_logged > a").on("touchstart", function (e) {
        e.preventDefault();
        addAccountMenu();
    });

    //action pour fermer le menu compte
    $(".topAccount.is_logged").on("mouseleave", removeAccountMenu);
    $(".shad").on("touchstart", removeAccountMenu);

    topAccount.mouseover(function () {

        $(this).addClass("hovering");
        topAccountRollover.addClass("opened");
        sahd.addClass("inMenu");
        sahd.addClass("actif");
    })
        .mouseout(function () {

            $(this).removeClass("hovering");
            topAccountRollover.removeClass("opened");
            sahd.removeClass("actif");
            sahd.removeClass("inMenu");
        });

    /* TOP CART ROLLOVER */
    var topCart = $("#cart_top");
    var topCartRollover = $(".topCartRollover");

    topCart.mouseover(function () {

        $(this).addClass("hovering");
        topCartRollover.addClass("opened");
        sahd.addClass("inMenu");
        sahd.addClass("actif");
    })
        .mouseout(function () {

            $(this).removeClass("hovering");
            topCartRollover.removeClass("opened");
            sahd.removeClass("actif");
            sahd.removeClass("inMenu");
        });

    /* HISTORY SLIDERS */
    var historyYears = $(".historyYears");
    var historyPics = $(".historyPics");
    var historyTxt = $(".historyTxt");

    if (historyYears.length) {

        historyYears = new Swiper('.historyYears', {

            slidesPerView: 14,
        });

        historyPics = new Swiper('.historyPics', {

            effect: 'fade',
            navigation: {

                prevEl: '.prevYear',
                nextEl: '.nextYear',
            },
            thumbs: {

                swiper: historyYears,
            },
        });

        historyTxt = new Swiper('.historyTxt', {

            effect: 'fade',
            slidesPerView: 1,
            autoHeight: true,
        });

        historyPics.controller.control = historyTxt;
        historyTxt.controller.control = historyPics;
    }

    /* PRODUCT SLIDERS */

    var mainSlideFp = $(".mainSlideFp");
    var thumbSlideFp = $(".thumbSlideFp");

    if (mainSlideFp.length) {

        thumbSlideFp = new Swiper('.thumbSlideFp', {

            slidesPerView: 3
        });

        mainSlideFp = new Swiper('.mainSlideFp', {

            slidesPerView: 1,
            effect: 'fade',
            thumbs: {

                swiper: thumbSlideFp
            },
            navigation: {

                prevEl: '.prevSlideFp',
                nextEl: '.nextSlideFp',
            }
        });
    }

    var productInformationsLabel = $(".productInformationsLabel");
    var productInformationsTxt = $(".productInformationsTxt");

    if (productInformationsLabel.length) {

        productInformationsLabel = new Swiper('.productInformationsLabel', {

            slidesPerView: 4
        });

        productInformationsTxt = new Swiper('.productInformationsTxt', {

            slidesPerView: 1,
            autoHeight: true,
            effect: 'fade',
            thumbs: {

                swiper: productInformationsLabel
            }
        });
    }

    var associations = $(".associations.as1");

    if (associations.length) {

        associations = new Swiper('.associations.as1', {

            slidesPerView: 2,
            navigation: {

                prevEl: '.prevAssoc.a',
                nextEl: '.nextAssoc.a'
            }
        });
    }

    var associations2 = $(".associations.as2");

    if (associations2.length) {

        associations2 = new Swiper('.associations.as2', {

            slidesPerView: 2,
            navigation: {

                prevEl: '.prevAssoc.b',
                nextEl: '.nextAssoc.b'
            }
        });
    }

    initProductSizeFieldset('.page_produit');

    /* SIZEGUIDE */
    var sizeguideNavSwiper = $("#sizeguideNavSwiper");

    if (sizeguideNavSwiper.length) {

        sizeguideNavSwiper = new Swiper('#sizeguideNavSwiper', {
            slidesPerView: 'auto',
            spaceBetween: 32,

            navigation: {
                prevEl: '.guideMainNav_nav .swiperButtonPrev',
                nextEl: '.guideMainNav_nav .swiperButtonNext'
            }
        });
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });
});

function closeSearchBar() {
    if ($('.shad').hasClass("search")) {
        $('.shad').removeClass("actif search");
        $('.wrap_search_field').removeClass("searching");
    }
}

function initProductSizeFieldset(parent) {
    /*  Function to simulate the behavior of a select */
    $(parent + " .productSizeFieldset").on("click touch", function () {


        var sizesList = $(".sizesList", $(this));
        var shadSelect = $(".shadSelect");

        sizesList.toggleClass("opened");
        new SimpleBar(sizesList[0]);
        $(this).toggleClass("actif");
        shadSelect.addClass("actif");

        shadSelect.on("click touch", function () {

            sizesList.removeClass("opened");
            $(this).removeClass("actif");
            $(parent + " .productSizeFieldset").removeClass("actif");
        });
    });
}

function btnFliterTrigger() {

    "use strict"

    var fliter_right = $(".fliter_right");
    var shad = $(".shad");
    var bodyrayon = $("body");

    if (fliter_right.hasClass("opened")) {

        hideFilters();
    } else {

        fliter_right.addClass("opened");
        bodyrayon.addClass("filter_on");


        shad.addClass("filterTrick", function () {

            shad.addClass("actif");
        });
    }

    $("#filter_caract .selected_options").hide();

    shad.on("click touch", function () {

        hideFilters();
    });
}

function hideFilters() {

    $(".filter_name.actif").click(); // Clic simulation to close opened filter

    setTimeout(function () {

        $(".fliter_right").removeClass("opened");
        $("body").removeClass("filter_on")
        $(".tri_price").removeClass("actif");
        $(".shad").removeClass("actif", function () {

            $(".shad").removeClass("filterTrick");
        });
    }, 300);
}

function addProdBoxGift(produit_id, panier_id) {
    if ($("#isGift").hasClass('checked') === false) {

        $("#isGift").addClass('checked');

        var productTitle = $("#isGift").data('product_add_basket_title');
        var productSize = $("#isGift").data('product_add_basket_size');
        var productColor = $("#isGift").data('product_add_basket_color');

        addToBasket(produit_id, '', panier_id, productTitle, productSize, null, productColor, null, null, 'panier', '', null, null, 'basket', null);

    } else {

        $("#isGift").removeClass('checked');
    }
}

function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_search'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();
                elm.value = 0;
                elm.checked = false;
            }
        }
    });
}

function soldGift() {
    $('.txt-ecart').addClass('step');
    $('.block-sold').addClass('actif');
}

function soldGiftClose() {
    $('.txt-ecart').removeClass('step');
    $('.block-sold').removeClass('actif');
}

$(document).on("scroll resize", function () {
    if (!$('body').hasClass('category')) {
        var productImgsWrapper = $(".productImgsWrapper");
        var productDescrWrapper = $(".productDescrWrapper");
        var scrollTop = $(document).scrollTop();

        if (scrollTop >= productImgsWrapper.innerHeight() - (productDescrWrapper.innerHeight() + 264)) {
            productDescrWrapper.addClass("absolute");
        } else {
            productDescrWrapper.removeClass("absolute");
        }
    }
});

/* AUTO-TOGGLE SCROLL ON CONTACT PAGE END */

if ($(".promo_swiper .swiper-slide").length > 0) {
    promoSwiper = new Swiper(".promo_swiper", {
        centeredSlides: true,
        slidesPerView: 1,
        autoplay: {
            delai: 5000,
        },
        navigation: {
            nextEl: '.navpromo.promoNext',
            prevEl: '.navpromo.promoPrev',
        }
    });
}

if ($(".home-module.cover-module .home-module-contents-2").length > 0) {
    $('.home-module.cover-module').addClass('module_2_cta');
}

$(document).ready(function () {
    $(window).resize(function () {
        var swiper_prod_assoc_img = $("#associations_slider .imgWrapper img").height();
        $("#associations_slider iframe").height(swiper_prod_assoc_img);
    });

    $("#backToGuide").bind('click', function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    if ($('#cart_total').length) {
        $('.intro_code').on('click touch', function () {
            $('#formPromo').stop().slideToggle('slow');
        });
    }

    setTimeout(function () {
        if ($("#cookiesLightbox").hasClass('notAccepted')) {
            $('#exit_overlay_OVI').addClass('cache');
        }
    }, 1000);
});
/* BLACK FRIDAY */
$(".navWrapper .main_menu_itm .submenu_wrapper .menu_categ_wrapper #categ_22, .navWrapper .main_menu_itm .submenu_wrapper .menu_categ_wrapper #categ_23").prependTo(".navWrapper");

function submitToCalcul(e) {
    if (e.keyCode && e.keyCode == "13") {
        calculCofidis3Cb();
    } else {
        return;
    }
}